import { SummaryUserConfiguration } from '../components/SummarizerConfigurator';
import { SummarizationType } from '../data/summary';
import { SummaryMetadata } from '../models/Summary';
import {
  isChromiumBrowser,
  isExtension,
  isFirefoxBrowser
} from '../utils/feature';

enum ExtensionScriptMethod {
  GetText = 'getText',
  GetMetadata = 'getMetadata'
}

const TAB_QUERY_INFO = { active: true, lastFocusedWindow: true };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestTab = async (method: ExtensionScriptMethod): Promise<any> => {
  if (!chrome.tabs) return null;

  try {
    const [tab] = await chrome.tabs.query(TAB_QUERY_INFO);

    if (tab && tab.id) {
      return await chrome.tabs.sendMessage(tab.id, {
        method
      });
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const extractTextFromActiveTab = async (): Promise<string | null> => {
  return requestTab(ExtensionScriptMethod.GetText);
};

const extractMetadataFromWebpage = async (): Promise<SummaryMetadata> => {
  const metadata: SummaryMetadata | null = await requestTab(
    ExtensionScriptMethod.GetMetadata
  );
  if (metadata?.url) {
    metadata.url = await getUrlFromActiveTab();
  }
  return metadata || {};
};

export const getUrlFromActiveTab = async (): Promise<string | undefined> =>
  new Promise((resolve) => {
    const tabsCallback = (tabs: browser.tabs.Tab[] | chrome.tabs.Tab[]) => {
      if (tabs[0] && tabs[0].url) {
        resolve(tabs[0].url);
      }
      resolve(undefined);
    };

    if (isChromiumBrowser() && chrome.tabs) {
      chrome.tabs.query(TAB_QUERY_INFO, tabsCallback);
    } else if (isFirefoxBrowser() && browser.tabs) {
      browser.tabs.query(TAB_QUERY_INFO).then(tabsCallback);
    } else resolve(undefined);
  });

export const getMetadata = async (
  config: SummaryUserConfiguration
): Promise<SummaryMetadata> => {
  const { type, source } = config;
  if (isExtension()) return extractMetadataFromWebpage();
  if (type !== SummarizationType.Url) return {};
  return {
    url: source
  };
};
