export enum Events {
  PaddlePaymentClose = 'PADDLE_PAYMENT_CLOSE',
  PaddlePaymentComplete = 'PADDLE_PAYMENT_COMPLETE'
}

export enum PaddleCheckoutState {
  PAYEMENT = 'PAYEMENT',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL'
}

export interface PaddlePaymentCompleteEvent {
  checkoutState: PaddleCheckoutState;
}
