import ChatbotsLogo from './ChatbotsLogo';
import { Button } from 'flowbite-react';

const ChatbotsBanner = () => {
  return (
    <div
      style={{ flex: '0 0 auto' }}
      className="w-[1138px] max-w-[100%] mx-auto mt-auto mb-[58px] p-16 bg-blue-600 bg-opacity-5 rounded-[5px] justify-start flex text-left content-between items-stretch max-[490px]:flex-wrap max-[700px]:px-5 max-[700px]:py-8 max-[700px]:mt-4"
    >
      <div className="min-w[380px] w-[50%] flex-col justify-start items-start gap-3 flex max-[490px]:basis-full max-[490px]:grow-0 max-[490px]:shrink-0">
        <div className="w-[147px] h-auto [&>svg]:w-full [&>svg]:h-auto relative max-[912px]:w-[120px]">
          <ChatbotsLogo />
        </div>
        <h3
          style={{ letterSpacing: '-1px' }}
          className="max-w-[372px] text-blue-600 text-4xl max-[912px]:text-3xl max-[700px]:text-2xl"
        >
          <strong>Chat</strong> with your long
          <br />
          texts and documents
        </h3>
        <p className="text-gray-900 text-lg font-normal py-[10px] max-[912px]:text-base max-[912px]:py-0 max-[912px]:pb-1 max-[700px]:text-sm">
          Transform Texts into Conversations with Ease
        </p>
        <Button
          className="bg-blue-600 hover:bg-blue-800 px-3 w-auto [&>span]:text-base font-medium rounded-[5px] flex"
          href="https://chatbots-app.myzone.ai?ref=recapiogpt"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          target="_blank"
        >
          Try Now For Free
        </Button>
      </div>
      <figure
        className="flex flex-auto items-stretch justify-end -mt-6 -mb-14 -mr-4 max-[912px]:-mr-12 max-[490px]:basis-full max-[490px]:grow-0 max-[490px]:shrink-0 max-[490px]:!m-0 max-[490px]:!bg-none"
        style={{
          backgroundImage: 'url(/chatbots/preview.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right',
          backgroundSize: 'contain'
        }}
      >
        <img
          className="min-[490px]:hidden max-[490px]:mx-auto max-[490px]:max-w-[300px] max-[490px]:!-mb-8 max-[490px]:!mt-4"
          src="/chatbots/preview.png"
          alt="MyZone AI Chatbots Preview"
          width={536}
          height={327}
        />
      </figure>
    </div>
  );
};

export default ChatbotsBanner;
