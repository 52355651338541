import { API } from 'aws-amplify';
import { Summary } from '../models/Summary';
import posthog from 'posthog-js';
import { UserInfo } from '../models/UserInfo';
import {
  SummaryConfiguration,
  SummaryProcessingStatus
} from '../utils/summary';
import { captureSummaryAPIRequest } from '../utils/metrics';
import { onUpdateSubscription } from '../utils/subscription';
import { SummarizationType } from '../data/summary';

const API_NAME = 'SummaryApi';
enum ApiEndpoint {
  User = '/me',
  Summary = '/summary',
  SummaryYoutube = '/summary-youtube',
  SavedSummary = '/summary/saved',
  RedeemCoupon = '/coupon',
  Upload = '/upload'
}

export const requestUserInfo = (): Promise<UserInfo> => {
  const myInit = {
    headers: {}
  };
  return API.get(API_NAME, ApiEndpoint.User, myInit)
    .then((data) => UserInfo.fromAPI(data))
    .then((userInfo) => {
      onUpdateSubscription(userInfo?.planName);
      return userInfo;
    });
};

export const createAccount = () => {
  return API.post(API_NAME, ApiEndpoint.User, {
    body: {}
  });
};

export const requestSummary = (
  config: SummaryConfiguration
): Promise<Summary> => {
  const postParams = {
    body: {
      type: config.type,
      source: config.source,
      processingLevel: config.processingLevel,
      metadata: config.metadata,
      prompt: config.prompt
    }
  };
  captureSummaryAPIRequest(SummaryProcessingStatus.Starting, config);
  // const endpointPath = config.type === 'Youtube' ? ApiEndpoint.SummaryYoutube : ApiEndpoint.Summary;
  const endpointPath = ApiEndpoint.Summary;
  return API.post(API_NAME, endpointPath, postParams)
    .then((data) => {
      captureSummaryAPIRequest(SummaryProcessingStatus.Successful, config);
      return Summary.fromAPI(data);
    })
    .catch((error) => {
      if (error?.response?.status !== 403) {
        captureSummaryAPIRequest(SummaryProcessingStatus.Error, config, {
          statusCode: error?.response?.status
        });
      }
      throw error;
    });
};

export const saveSummary = (summaryId: string): Promise<void> => {
  const putParams = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  return API.put(
    API_NAME,
    ApiEndpoint.SavedSummary + '/' + summaryId,
    putParams
  );
};

export const deleteSavedSummary = (summaryId: string): Promise<void> => {
  return API.del(API_NAME, ApiEndpoint.SavedSummary + '/' + summaryId, {});
};

interface LatestSavedSummaryResult {
  nextPageToken: string | undefined;
  summaries: Summary[];
}

export const getLatestSavedSummary = (
  nextPageToken?: string
): Promise<LatestSavedSummaryResult> => {
  const getParams = {
    response: false,
    queryStringParameters: {}
  };
  if (nextPageToken) {
    getParams.queryStringParameters = {
      nextPageToken
    };
  }
  return API.get(API_NAME, ApiEndpoint.SavedSummary, getParams).then(
    (data) => ({
      nextPageToken: data.nextPageToken,
      summaries: data.summaries.map((summary: never) =>
        Summary.fromAPI(summary)
      )
    })
  );
};

export const updateSavedSummary = (summary: Summary): Promise<void> => {
  const postParams = {
    body: summary
  };
  if (summary.type === SummarizationType.Youtube) {
    throw new Error();
  }
  return API.post(
    API_NAME,
    ApiEndpoint.SavedSummary + '/' + summary.summaryId,
    postParams
  );
};

export const redeemCoupon = (couponId: string): Promise<void> => {
  const postParams = {
    body: { couponId }
  };
  return API.post(API_NAME, ApiEndpoint.RedeemCoupon, postParams)
    .then(() => {
      posthog.capture('RedeemCoupon', {
        couponId,
        status: 'Successful'
      });
    })
    .catch((error) => {
      posthog.capture('RedeemCoupon', {
        couponId,
        status: 'Error'
      });
      throw error;
    });
};

export interface UploadResponse {
  fileKey: string;
  url: string;
}

export const getUploadUrl = (
  fileName: string,
  fileType: string
): Promise<UploadResponse> =>
  API.get(API_NAME, ApiEndpoint.Upload, {
    queryStringParameters: {
      fileName,
      fileType
    }
  });
