import { posthog } from 'posthog-js';
import { toast } from 'react-toastify';
import { getUploadUrl } from '../api/api';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosProgressEvent } from 'axios';
import { Progress } from 'flowbite-react';

const ALLOWED_FILE_EXTENSIONS = ['pdf', 'txt', 'docx', 'pptx'];

export const captureFileUpload = (
  eventName: string,
  type: string,
  file: File
) => {
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  posthog.capture(eventName, {
    type,
    fileExtension,
    fileName: file.name.toLowerCase(),
    filetype: file.type,
    fileSize: file.size
  });
};

export const uploadFileToServer = async (
  file: File,
  onUploadProgress: (percentage: number) => void
): Promise<string> => {
  const { url, fileKey } = await getUploadUrl(
    file.name.toLowerCase(),
    file.type
  );
  const headers = {
    'Content-Type': file.type
  };

  return axios
    .put(url, file, {
      headers,
      onUploadProgress: (progress: AxiosProgressEvent) =>
        progress.total &&
        onUploadProgress(Math.round((progress.loaded * 100) / progress.total))
    })
    .then(() => fileKey);
};

type FileInputProps = {
  uploadProgress: number;
  selectedFile: File | undefined;
  setSelectedFile: (file: File) => void;
};

export const FileInput: React.FC<FileInputProps> = ({
  uploadProgress,
  selectedFile,
  setSelectedFile
}) => {
  const { t } = useTranslation();
  const dropRef = useRef<HTMLDivElement>(null);

  const onUpload = (files: FileList | null) => {
    if (!files || files.length < 1) {
      return toast.error(t('fileUpload.check.noFileSelected'));
    }
    if (files.length > 1) {
      return toast.error(t('fileUpload.check.tooManyFiles'));
    }

    const file = files[0];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      captureFileUpload('FileInputError', 'InvalidFileExtension', file);
      toast.error(
        t('fileUpload.check.extensionNotAllowed', {
          fileExtension
        })
      );
    } else if (file.size > 30000000) {
      captureFileUpload('FileInputError', 'InvalidFileSize', file);
      toast.error(t('fileUpload.check.sizeLimitExceeded'));
    } else setSelectedFile(file);
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const dataTransfer = event.dataTransfer;
    if (!dataTransfer) toast.error(t('fileUpload.check.noFileSelected'));
    else onUpload(dataTransfer.files);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(event.target.files);
  };

  useEffect(() => {
    dropRef?.current?.addEventListener('dragover', handleDragOver);
    dropRef?.current?.addEventListener('drop', handleDrop);

    return () => {
      dropRef?.current?.removeEventListener('dragover', handleDragOver);
      dropRef?.current?.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div ref={dropRef} className="flex items-center justify-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            className="w-10 h-10 mb-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          {selectedFile && (
            <p className="mb-2 text-base text-gray-700 dark:text-gray-600">
              <span className="font-semibold">
                {t('fileUpload.input.selected', {
                  fileName: selectedFile.name
                })}
              </span>
            </p>
          )}
          {uploadProgress ? (
            <Progress className="w-56 sm:w-96 m-2" progress={uploadProgress} />
          ) : (
            <>
              <p className="mb-2 text-base text-gray-500 dark:text-gray-400">
                <span className="font-semibold">
                  {t('fileUpload.input.click')}
                </span>{' '}
                {t('fileUpload.input.drag')}
              </p>
              <p className="text-base text-gray-500 dark:text-gray-400">
                {t('fileUpload.input.format')}
              </p>
            </>
          )}
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={handleInputChange}
        />
      </label>
    </div>
  );
};
