import { useState, createContext, FC, useContext } from 'react';

export enum Layout {
  Creator = 'Creator',
  Saved = 'Saved',
  Profile = 'Profile'
}

interface LayoutContextProps {
  layout: Layout;
  setLayout: (layout: Layout) => void;
}

const defaultLayout: LayoutContextProps = {
  layout: Layout.Creator,
  setLayout: () => undefined
};

const LayoutContext = createContext<LayoutContextProps>(defaultLayout);

interface Props {
  children: React.ReactNode;
}

export const LayoutProvider: FC<Props> = ({ children }) => {
  const [layout, setLayout] = useState<Layout>(defaultLayout.layout);

  return (
    <LayoutContext.Provider value={{ layout, setLayout }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
