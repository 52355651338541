import moment from 'moment';
import { ProcessingLevel, SummarizationType } from '../data/summary';
import { enumFromStringValue } from '../utils/object';
import { getYoutubeUrlFromId } from '../utils/isYoutubeUrl';

export interface SummaryMetadata {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  websiteName?: string;
  contentType?: string;
}

export interface ApiSummaryResponse {
  type: string;
  source: string;
  summary: string;
  processingLevel: string;
  likeId?: string;
  metadata?: SummaryMetadata;
  summaryId: string;
  shareUrl?: string;
  created: string;
  modified: string;
}

export class Summary {
  type: SummarizationType;
  source: string;
  summary: string;
  processingLevel: ProcessingLevel;
  likeId?: string;
  metadata?: SummaryMetadata;
  summaryId: string;
  shareUrl?: string;
  created: moment.Moment;
  modified: moment.Moment;

  constructor(
    type: string,
    source: string,
    summary: string,
    processingLevel: string,
    summaryId: string,
    created: string,
    modified: string,
    shareUrl?: string,
    metadata?: SummaryMetadata,
    likeId?: string
  ) {
    this.type =
      enumFromStringValue(SummarizationType, type) || SummarizationType.Unknown;
    this.source = source;
    this.summary = summary;
    this.processingLevel =
      enumFromStringValue(ProcessingLevel, processingLevel) ||
      ProcessingLevel.Unknown;
    this.summaryId = summaryId;
    this.created = moment(created);
    this.modified = moment(modified);
    this.shareUrl = shareUrl;
    this.metadata = metadata;
    this.likeId = likeId;
  }

  getOriginalUrl(): string | undefined {
    if (this.type === SummarizationType.Youtube) {
      return getYoutubeUrlFromId(this.source);
    }

    return (
      this.metadata?.url ??
      (this.type === SummarizationType.Url ? this.source : undefined)
    );
  }

  getTitle(): string {
    return this.metadata?.title ?? this.summary?.split('.')[0] + '...';
  }

  toObject(): ApiSummaryResponse {
    return {
      type: this.type,
      source: this.source,
      summary: this.summary,
      processingLevel: this.processingLevel,
      summaryId: this.summaryId,
      created: this.created.toISOString(),
      modified: this.modified.toISOString(),
      shareUrl: this.shareUrl,
      metadata: this.metadata,
      likeId: this.likeId
    };
  }

  updateSummaryText(summary: string): Summary {
    return new Summary(
      this.type,
      this.source,
      summary,
      this.processingLevel,
      this.summaryId,
      this.created.toISOString(),
      this.modified.toISOString(),
      this.shareUrl,
      this.metadata,
      this.likeId
    );
  }

  static fromAPI(item?: ApiSummaryResponse): Summary {
    if (!item) throw new Error('No item!');
    return new Summary(
      item.type,
      item.source,
      item.summary,
      item.processingLevel,
      item.summaryId,
      item.created,
      item.modified,
      item.shareUrl,
      item.metadata,
      item.likeId
    );
  }
}
