import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout, useLayout } from '../hooks/useLayout';
import { isExtension } from '../utils/feature';
import { SummaryErrorReason } from '../utils/metrics';

type LayoutContent = {
  title: string;
  subtitle: string;
  CTA: string;
  buttons: {
    key: string;
    href: string;
    text: string;
    open: boolean;
  }[];
};

const YoutubeSummaryError: LayoutContent = {
  title: 'noContent.summary.youtube.title',
  subtitle: 'noContent.summary.youtube.subtitle',
  CTA: 'noContent.summary.youtube.CTA',
  buttons: []
};

const DefaultSummaryError: LayoutContent = {
  title: 'noContent.summary.default.title',
  subtitle: 'noContent.summary.default.subtitle',
  CTA: 'noContent.summary.default.CTA',
  buttons: []
};

const getSummaryErrorLayout = (reason?: SummaryErrorReason): LayoutContent => {
  switch (reason) {
    case SummaryErrorReason.UnprocessableYoutubeContent:
      return YoutubeSummaryError;
    default:
      return DefaultSummaryError;
  }
};

const SavedSummaryLayout: LayoutContent = {
  title: 'noContent.saved.title',
  subtitle: 'noContent.saved.subtitle',
  CTA: 'noContent.saved.CTA',
  buttons: [
    {
      key: 'GENERATE_SUMMARY',
      href: '/',
      text: 'noContent.saved.buttons.generate',
      open: false
    }
  ]
};

const getLayoutContent = (reason?: SummaryErrorReason) => {
  const { layout } = useLayout();

  switch (layout) {
    case Layout.Creator:
      return getSummaryErrorLayout(reason);
    case Layout.Saved:
      return SavedSummaryLayout;
    default:
      throw new Error('No layout for: ' + layout);
  }
};

type NoContentProps = {
  reason?: SummaryErrorReason;
};

const NoContent: React.FC<NoContentProps> = ({ reason }) => {
  const { title, subtitle, CTA, buttons } = getLayoutContent(reason);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mx-auto p-4 flex flex-col justify-center items-center">
      <div className="flex flex-col items-center text-center p-4">
        <div className="w-full">
          <div className="text-3xl font-medium p-4">{t(title)}</div>
          <div className="text-xl font-medium mb-4">{t(subtitle)}</div>
          <div className="text-lg">{t(CTA)}</div>
        </div>
        {buttons.length > 0 && (
          <div className="flex w-full justify-around mt-8">
            {buttons.map((btn) => (
              <Button
                onClick={() =>
                  btn.open || isExtension()
                    ? window.open(btn.href, 'blank')
                    : navigate(btn.href)
                }
                key={btn.key}
                className="bg-blue-500 hover:bg-blue-600 px-4 mt-3"
              >
                {t(btn.text)}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NoContent;
