import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDevelopment, isExtension, isPreProd } from './feature';
import posthog from 'posthog-js';

I18n.putVocabularies(translations);

const i18Config: InitOptions = {
  fallbackLng: 'en',
  debug: isDevelopment() || isPreProd(),
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
};

if (isExtension() && !isDevelopment()) {
  i18Config.backend = {
    loadPath: (lngs: string[]) => {
      const lng = lngs[0];
      const lanuage = lng.length > 2 ? lng.substring(0, 2) : lng;
      return chrome.runtime.getURL(`/locales/${lanuage}/translation.json`);
    }
  };
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18Config, () => {
    const lanuage =
      i18n.language.length > 2 ? i18n.language.substring(0, 2) : i18n.language;
    I18n.setLanguage(lanuage);
  });

export const onLanguageChange = (lng: string) => {
  posthog.capture('ChangeLanguage', { language: lng });
  i18n.changeLanguage(lng);
  I18n.setLanguage(lng);
};

export default i18n;
