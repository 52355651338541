import { Button, Label, Tabs, Textarea, TextInput } from 'flowbite-react';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  extractTextFromActiveTab,
  getUrlFromActiveTab
} from '../api/extension';
import { SummarizationType } from '../data/summary';
import { isAuthentificated, isLoggedInCached, useAuth } from '../hooks/useAuth';
import { isExtension } from '../utils/feature';
import { WebAdvancedConfigurator } from './AdvancedSummarizerConfigurator';
import { PROMPT_KEY } from './ExtensionSettings';
import { captureFileUpload, FileInput, uploadFileToServer } from './FileInput';
import { isYoutubeUrl } from '../utils/isYoutubeUrl';
// import { preSummaryzeText } from '../utils/text';
interface WebsiteMatch {
  url: string;
  tag: string;
}

const TEXT_LOCAL_STORAGE_KEY = 'text_to_summarize';
const URL_LOCAL_STORAGE_KEY = 'url_to_summarize';
const YOUTUBE_URL_LOCAL_STORAGE_KEY = 'youtube_url_to_summarize';

const DEFAULT_PROMPT =
  'Summarize the following text in 3 longs paragraphs. The summary should be in {language}:\n{text_to_summarize}\n';

const EXTENSION_DISABLED_WEBSITES: WebsiteMatch[] = [
  {
    url: 'https://app.recapiogpt.com/',
    tag: 'RecapioGPT'
  }
];

const DENY_LISTED_WEBSITES: WebsiteMatch[] = [];

const EXTENSION_URL_WEBSITES: WebsiteMatch[] = [
  {
    url: 'https://youtu.be/.*',
    tag: 'Youtube'
  },
  {
    url: 'https://www.youtube.com/.*',
    tag: 'Youtube'
  }
];

const RESTRICTED_WEBSITES: WebsiteMatch[] = [
  {
    url: 'https://docs.google.com/.*/edit',
    tag: 'GoogleDocs'
  },
  {
    url: 'https://chat.openai.com/',
    tag: 'ChatGPT'
  }
];

const isExtensionDisabledUrl = (url: string) =>
  EXTENSION_DISABLED_WEBSITES.find(
    (disabledUrl) => url.match(disabledUrl.url) && isExtension()
  );

const isExtensionUrlOnlyWebsite = (url: string) =>
  EXTENSION_URL_WEBSITES.find(
    (extensionUrl) => url.match(extensionUrl.url) && isExtension()
  );

const isRestrictedUrl = (url: string) => {
  const restrictedUrl = RESTRICTED_WEBSITES.find(
    (restrictedUrl) => url.match(restrictedUrl.url) && !isExtension()
  );
  if (restrictedUrl) {
    posthog.capture('SummaryInputCheck', {
      type: restrictedUrl.tag,
      value: url
    });
    return true;
  }
  return false;
};

const getDenyListedUrl = (url: string) =>
  DENY_LISTED_WEBSITES.find((denyListedUrl) => url.match(denyListedUrl.url));

const isDenyListedUrl = (url: string) => {
  const denyListedUrl = getDenyListedUrl(url);
  if (denyListedUrl) {
    posthog.capture('SummaryInputCheck', {
      type: denyListedUrl.tag,
      value: url
    });
    return true;
  }
  return false;
};

const isWebsite = (url: string) => url.substring(0, 4) === 'http';
const isLocalFile = (url: string) => url.substring(0, 4) === 'file';
const isTextTooShort = (text: string) => text.length < 300;

const getPromptFromLocalStorage = () => {
  return localStorage.getItem(PROMPT_KEY) || DEFAULT_PROMPT;
};

const getAutoSummaryEnabled = () => {
  return (localStorage.getItem('autoSummaryEnabled') || 'true') === 'true';
};

// const createFileFromText = (text: string): File => {
//   const blob = new Blob([text], { type: 'text/plain' });
//   return new File([blob], 'file.txt', { type: 'text/plain' });
// };

export interface SummaryUserConfiguration {
  type: SummarizationType;
  source: string;
  prompt: string;
}
interface Props {
  generateSummary: (config: SummaryUserConfiguration) => void;
  summarizationFailed: boolean;
  isInitialSummary: boolean;
  hidden: boolean;
  activeSummarizationTypeTab: SummarizationType;
  setActiveSummarizationTypeTabChange: (tabId: SummarizationType) => void;
}

export const SummarizerConfigurator: React.FC<Props> = ({
  generateSummary,
  summarizationFailed,
  isInitialSummary,
  hidden,
  activeSummarizationTypeTab,
  setActiveSummarizationTypeTabChange
}) => {
  const { t } = useTranslation();
  const user = useAuth();
  const isAuth = isAuthentificated();
  const [url, setUrl] = useState(
    sessionStorage.getItem(URL_LOCAL_STORAGE_KEY) || ''
  );
  const [youtubeUrl, setYoutubeUrl] = useState(
    sessionStorage.getItem(YOUTUBE_URL_LOCAL_STORAGE_KEY) || ''
  );
  const [text, setText] = useState(
    sessionStorage.getItem(TEXT_LOCAL_STORAGE_KEY) || ''
  );
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [prompt, setPrompt] = useState(getPromptFromLocalStorage());
  const [isValidExtensionUrl, setIsValidExtensionUrl] = useState(true);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

  const generateTextSummary = (rawText: string) => {
    // const preSummarizedText = preSummaryzeText(rawText);
    if (isTextTooShort(rawText)) {
      toast.error(t('summary.configuration.error.tooShort'));
      posthog.capture('SummaryInputCheck', {
        type: 'tooShort',
        value: text,
        length: rawText.length
      });
      setIsValidExtensionUrl(false);
    } else {
      resetInputs();
      // if (rawText.length < 30000) {
      generateSummary({
        type: SummarizationType.Text,
        source: rawText,
        prompt: prompt
      });
      // } else {
      //   const file = createFileFromText(rawText);
      //   generateFileSummary(file, true);
      // }
    }
  };

  const saveConfigurationToSessionStorage = () => {
    sessionStorage.setItem(TEXT_LOCAL_STORAGE_KEY, text);
    sessionStorage.setItem(URL_LOCAL_STORAGE_KEY, url);
    sessionStorage.setItem(YOUTUBE_URL_LOCAL_STORAGE_KEY, youtubeUrl);
  };

  const retrieveWebpageContentAndGenerateSummary = async () => {
    const isAutoSummaryEnabled = getAutoSummaryEnabled();
    const url = await getUrlFromActiveTab();
    if (url && isExtensionUrlOnlyWebsite(url)) {
      return generateUrlSummary(url);
    } else if (url && isExtensionDisabledUrl(url)) {
      return setIsValidExtensionUrl(false);
    }

    const webpageText = await extractTextFromActiveTab();
    if (webpageText) {
      if (isAutoSummaryEnabled) {
        return generateTextSummary(webpageText);
      }
      setText(webpageText);
      setActiveSummarizationTypeTabChange(SummarizationType.Text);
      return setIsValidExtensionUrl(false);
    }

    if (!url || !isWebsite(url)) {
      if (url && isLocalFile(url)) {
        setActiveSummarizationTypeTabChange(SummarizationType.File);
      }
      return setIsValidExtensionUrl(false);
    }

    if (isAutoSummaryEnabled) {
      return generateUrlSummary(url);
    }

    setUrl(url);
    setIsValidExtensionUrl(false);
  };

  useEffect(() => {
    if (isInitialSummary && isExtension()) {
      if ((isLoggedInCached() && isAuth) || (!isLoggedInCached() && !isAuth)) {
        retrieveWebpageContentAndGenerateSummary();
      }
    }
  }, [user]);

  const resetInputs = () => {
    saveConfigurationToSessionStorage();
    setText('');
    setUrl('');
    setYoutubeUrl('');
  };

  const generateUrlSummary = (
    url: string,
    config: { isYoutube?: boolean } = {}
  ) => {
    const isYoutube = Boolean(config?.isYoutube);

    if (isLocalFile(url)) {
      posthog.capture('SummaryInputCheck', { type: 'LocalFile', value: url });
      toast.error(t('summary.configuration.error.localFile'));
      setIsValidExtensionUrl(false);
    } else if (!isWebsite(url)) {
      posthog.capture('SummaryInputCheck', {
        type: 'InvalidWebsite',
        value: url
      });
      toast.error(t('summary.configuration.error.url'));
      setIsValidExtensionUrl(false);
    } else if (isDenyListedUrl(url)) {
      const denyListedUrl = getDenyListedUrl(url);
      if (denyListedUrl) {
        toast.error(
          t(`summary.configuration.error.notSupported.${denyListedUrl.tag}`)
        );
        setIsValidExtensionUrl(false);
      }
    } else if (isRestrictedUrl(url)) {
      toast.error(t('summary.configuration.error.useExtension'));
      setIsValidExtensionUrl(false);
    } else {
      resetInputs();
      generateSummary({
        type: isYoutube ? SummarizationType.Youtube : SummarizationType.Url,
        source: url,
        prompt: prompt
      });
    }
  };

  const generateFileSummary = (file: File, isSilent = false) => {
    captureFileUpload('FileUpload', 'Starting', file);
    setIsGeneratingSummary(true);
    if (!isSilent) toast.info(t('fileUpload.message.starting'));
    uploadFileToServer(file, setUploadProgress)
      .then((fileKey) => {
        captureFileUpload('FileUpload', 'Success', file);
        if (!isSilent) toast.success(t('fileUpload.message.success'));
        generateSummary({
          type: SummarizationType.File,
          source: fileKey,
          prompt: prompt
        });
      })
      .catch(() => {
        captureFileUpload('FileUpload', 'Error', file);
        setIsGeneratingSummary(false);
        if (!isSilent) toast.error(t('fileUpload.message.error'));
      });
  };

  const onSelectFile = (file: File) => {
    setUploadProgress(0);
    setSelectedFile(file);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    switch (activeSummarizationTypeTab) {
      case SummarizationType.Text:
        if (text) generateTextSummary(text);
        else toast.error(t('summary.configuration.error.emptyText'));
        break;
      case SummarizationType.Url:
        if (url) generateUrlSummary(url, { isYoutube: isYoutubeUrl(url) });
        else toast.error(t('summary.configuration.error.emptyUrl'));
        break;
      case SummarizationType.Youtube:
        if (youtubeUrl) {
          generateUrlSummary(youtubeUrl, {
            isYoutube: isYoutubeUrl(youtubeUrl)
          });
        } else {
          toast.error(t('summary.configuration.error.emptyYoutubeUrl'));
        }
        break;
      case SummarizationType.File:
        if (selectedFile) generateFileSummary(selectedFile);
        else toast.error(t('summary.configuration.error.emptyFile'));
        break;
      default:
        throw new Error('Error handling genration submit');
    }
  };

  const onActiveTabChange = (tab: number) => {
    switch (tab) {
      case 0:
        setActiveSummarizationTypeTabChange(SummarizationType.Url);
        break;
      case 1:
        setActiveSummarizationTypeTabChange(SummarizationType.Youtube);
        break;
      case 2:
        setActiveSummarizationTypeTabChange(SummarizationType.Text);
        break;
      case 3:
        setActiveSummarizationTypeTabChange(SummarizationType.File);
        break;
    }
  };

  if (isExtension() && isValidExtensionUrl && !summarizationFailed) {
    return <></>;
  }

  return (
    <div
      className="flex flex-col sm:mt-0 py-5 px-5 space-y-10 items-center"
      hidden={hidden}
    >
      {isInitialSummary && (
        <div className="flex flex-col items-center gap-y-2">
          <p className="font-bold text-2xl text-center">
            {t('summary.configuration.title')}
          </p>
          <p className="md:w-2/3 lg:w-5/6 xl:w-2/3 text-center text-md">
            {t('summary.configuration.description')}
          </p>
        </div>
      )}
      <form
        className="flex w-full lg:w-5/6 xl:w-2/3 flex-col gap-4 items-center"
        onSubmit={handleSubmit}
      >
        {!isInitialSummary && (
          <p className="font-semibold text-xl">
            {t('summary.configuration.newGeneration')}
          </p>
        )}
        <Tabs.Group
          aria-label="Tabs with underline"
          style="underline"
          className="w-full"
          onActiveTabChange={(tab) => onActiveTabChange(tab)}
        >
          <Tabs.Item
            active={activeSummarizationTypeTab === SummarizationType.Url}
            title={t('summary.configuration.form.url.tab') || 'Url'}
          >
            <div className="w-full">
              <div className="mb-2 block">
                <Label
                  htmlFor="url"
                  value={t('summary.configuration.form.url.label') || 'Url'}
                />
              </div>
              <TextInput
                id="url"
                type="url"
                className="appearance-none border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                placeholder={
                  t('summary.configuration.form.url.placeholder') || ''
                }
                value={url}
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
          </Tabs.Item>
          <Tabs.Item
            active={activeSummarizationTypeTab === SummarizationType.Youtube}
            title={
              <>
                {t('summary.configuration.form.youtube.tab') || 'YouTube'}
                <svg
                  className={`w-7 h-7 ml-1 -mt-2 ${
                    activeSummarizationTypeTab === SummarizationType.Youtube
                      ? 'fill-blue-600'
                      : 'fill-blue-500'
                  }`}
                  width="800px"
                  height="800px"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="M42.3,24l3.4-5.1a2,2,0,0,0,.2-1.7A1.8,1.8,0,0,0,44.7,16l-5.9-2.4-.5-5.9a2.1,2.1,0,0,0-.7-1.5,2,2,0,0,0-1.7-.3L29.6,7.2,25.5,2.6a2.2,2.2,0,0,0-3,0L18.4,7.2,12.1,5.9a2,2,0,0,0-1.7.3,2.1,2.1,0,0,0-.7,1.5l-.5,5.9L3.3,16a1.8,1.8,0,0,0-1.2,1.2,2,2,0,0,0,.2,1.7L5.7,24,2.3,29.1a2,2,0,0,0,1,2.9l5.9,2.4.5,5.9a2.1,2.1,0,0,0,.7,1.5,2,2,0,0,0,1.7.3l6.3-1.3,4.1,4.5a2,2,0,0,0,3,0l4.1-4.5,6.3,1.3a2,2,0,0,0,1.7-.3,2.1,2.1,0,0,0,.7-1.5l.5-5.9L44.7,32a2,2,0,0,0,1-2.9ZM18,31.1l-4.2-3.2L12.7,27h-.1l.6,1.4,1.7,4-2.1.8L9.3,24.6l2.1-.8L15.7,27l1.1.9h0a11.8,11.8,0,0,0-.6-1.3l-1.6-4.1,2.1-.9,3.5,8.6Zm3.3-1.3-3.5-8.7,6.6-2.6.7,1.8L20.7,22l.6,1.6L25.1,22l.7,1.7L22,25.2l.7,1.9,4.5-1.8.7,1.8Zm13.9-5.7-2.6-3.7-.9-1.5h-.1a14.7,14.7,0,0,1,.4,1.7l.8,4.5-2.1.9-5.9-7.7,2.2-.9,2.3,3.3,1.3,2h0a22.4,22.4,0,0,1-.4-2.3l-.7-4,2-.8L33.8,19,35,20.9h0s-.2-1.4-.4-2.4L34,14.6l2.1-.9,1.2,9.6Z" />
                  </g>
                </svg>
              </>
            }
          >
            <div className="w-full">
              <div className="mb-2 block">
                <Label
                  htmlFor="url"
                  value={t('summary.configuration.form.youtube.label') || 'Url'}
                />
              </div>
              <TextInput
                id="youtube-url"
                type="url"
                className="appearance-none border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                placeholder={
                  t('summary.configuration.form.youtube.placeholder') || ''
                }
                value={youtubeUrl}
                onChange={(event) => setYoutubeUrl(event.target.value)}
              />
            </div>
          </Tabs.Item>
          <Tabs.Item
            active={activeSummarizationTypeTab === SummarizationType.Text}
            title={t('summary.configuration.form.text.tab') || 'Text'}
          >
            <div className="w-full">
              <div className="mb-2 block">
                <Label
                  htmlFor="url"
                  value={t('summary.configuration.form.text.label') || 'Url'}
                />
              </div>
              <Textarea
                id="text"
                rows={text == '' ? 4 : 12}
                className="appearance-none border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 text-justify w-full"
                placeholder={
                  t('summary.configuration.form.text.placeholder') || ''
                }
                value={text}
                onChange={(event) => setText(event.target.value)}
              />
            </div>
          </Tabs.Item>
          <Tabs.Item
            active={activeSummarizationTypeTab === SummarizationType.File}
            title={t('summary.configuration.form.file.tab') || 'File'}
          >
            <div className="w-full">
              <div className="mb-2 block">
                <Label
                  htmlFor="url"
                  value={t('summary.configuration.form.file.label') || 'Url'}
                />
              </div>
              <FileInput
                uploadProgress={uploadProgress}
                selectedFile={selectedFile}
                setSelectedFile={onSelectFile}
              />
            </div>
          </Tabs.Item>
        </Tabs.Group>
        {activeSummarizationTypeTab !== SummarizationType.Youtube && (
          <WebAdvancedConfigurator setPrompt={setPrompt} />
        )}
        <Button
          className="bg-blue-500 hover:bg-blue-600 px-4 self-end w-full md:w-1/3 xl:md:w-1/4"
          disabled={isGeneratingSummary}
          type="submit"
        >
          {t('summary.configuration.form.button')}
        </Button>
      </form>
    </div>
  );
};
