/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:635b58fd-dd1e-425b-9548-d4f027d50662',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_02GYs7LK1',
  aws_user_pools_web_client_id: '6jt7tacjmhjet1b4ugt4giqfar',
  oauth: {
    domain: 'x8nd7u2i8gva-dev.auth.eu-west-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
      'REQUIRES_UPPERCASE'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cloud_logic_custom: [
    {
      name: 'SummaryApi',
      endpoint: 'https://y56lllxqcb.execute-api.eu-west-1.amazonaws.com/dev',
      region: 'eu-west-1'
    }
  ],
  aws_dynamodb_all_tables_region: 'eu-west-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'UserDB-dev',
      region: 'eu-west-1'
    }
  ]
};

export default awsmobile;
