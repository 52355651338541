import { Button, useAuthenticator, View } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { isExtension } from '../utils/feature';

export const ProtectedRoutesAuthComponents = {
  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();
      const { t } = useTranslation();

      const signUp = () =>
        window.open(`https://app.recapiogpt.com/profile?initialState=signUp`);

      return (
        <View textAlign="center" className="flex flex-col">
          {isExtension() && (
            <div className="mx-8 -mt-5">
              <button
                onClick={signUp}
                className="amplify-button amplify-field-group__control amplify-button--primary amplify-button--fullwidth"
              >
                {t('account.signUp')}
              </button>
            </div>
          )}
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            {t('account.forgotPassword')}
          </Button>
        </View>
      );
    }
  }
};
