import { ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isExtension } from '../utils/feature';
import { ExtensionAdvancedConfigurator } from './AdvancedSummarizerConfigurator';

export const AUTO_SUMMARY_ENABLED_KEY = 'autoSummaryEnabled';
export const PROMPT_KEY = 'prompt';

const getAutoSummaryEnabled = () => {
  const autoSummaryEnabled =
    localStorage.getItem(AUTO_SUMMARY_ENABLED_KEY) || 'true';
  return autoSummaryEnabled === 'true';
};

const setAutoSummaryEnabled = (value: boolean) => {
  localStorage.setItem(AUTO_SUMMARY_ENABLED_KEY, value.toString());
};

const setPrompt = (value: string) => {
  localStorage.setItem(PROMPT_KEY, value);
};

export const ExtensionSettings: React.FC = () => {
  const [isAutoSummaryEnabled, setIsAutoSummaryEnabled] = useState<boolean>(
    getAutoSummaryEnabled()
  );
  const { t } = useTranslation();

  const onAutoSummaryChange = (value: boolean) => {
    setAutoSummaryEnabled(value);
    setIsAutoSummaryEnabled(value);
  };

  if (!isExtension()) return <></>;
  return (
    <div>
      <div className="text-3xl font-medium p-4 text-center">
        {t('settings.title')}
      </div>
      <ToggleSwitch
        checked={isAutoSummaryEnabled}
        label={t('settings.autoSummary')}
        onChange={onAutoSummaryChange}
      />
      <ExtensionAdvancedConfigurator setPrompt={setPrompt} />
    </div>
  );
};
