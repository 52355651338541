import { useEffect, useState } from 'react';
import { requestUserInfo } from '../api/api';
import { ExtensionSettings } from '../components/ExtensionSettings';
import { ManageAccount } from '../components/ManageAccount';
import { PricingDisplay } from '../components/pricing/PricingDisplay';
import { ProfileInformation } from '../components/ProfileInformation';
import { ProtectedLayout } from '../components/ProtectedLayout';
import { useAuth } from '../hooks/useAuth';
import { UserInfo } from '../models/UserInfo';
import { Events } from '../utils/events';
import { enumFromStringValue } from '../utils/object';
import {
  isStorageLifeTimeSubscription,
  getStorageSubscriptionName
} from '../utils/storage';
import {
  isLifeTimeSubscription,
  SubscritptionName
} from '../utils/subscription';

const Profile = () => {
  const [userData, setUserData] = useState<UserInfo>();
  const [subscritptionName, setSubscritptionName] = useState<SubscritptionName>(
    SubscritptionName.UNKNOWN
  );
  const user = useAuth();

  const isLifetimeSubscription =
    isLifeTimeSubscription(userData?.planName || SubscritptionName.UNKNOWN) ||
    isStorageLifeTimeSubscription();

  useEffect(() => {
    const lifetimeSubscriptionType = getStorageSubscriptionName();
    const lifetimeSubscriptionPlanName = lifetimeSubscriptionType
      ? enumFromStringValue(SubscritptionName, lifetimeSubscriptionType) ||
        SubscritptionName.UNKNOWN
      : userData?.planName || SubscritptionName.UNKNOWN;
    setSubscritptionName(lifetimeSubscriptionPlanName);
  }, [userData]);

  const fetchUserInfo = () => requestUserInfo().then(setUserData);

  useEffect(() => {
    document.addEventListener(Events.PaddlePaymentClose, fetchUserInfo);
    return () => {
      document.removeEventListener(Events.PaddlePaymentClose, fetchUserInfo);
    };
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserInfo();
    }
  }, [user]);

  return (
    <ProtectedLayout>
      <div className="mx-auto p-4 flex-1 flex flex-col justify-center items-center w-full">
        <div className="flex flex-col items-center p-4  w-full">
          <div className="w-full flex flex-col items-center w-full">
            <ExtensionSettings />
            <ProfileInformation
              userData={userData}
              isLifetimeSubscription={isLifetimeSubscription}
              subscritptionName={subscritptionName}
            />
            <ManageAccount userData={userData} />
          </div>
          <PricingDisplay
            userData={userData}
            isLifetimeSubscription={isLifetimeSubscription}
          />
        </div>
      </div>
    </ProtectedLayout>
  );
};

export default Profile;
