import { useState, createContext, FC, useContext } from 'react';
import { UpgradeSubscriptionModal } from '../components/modals/UpgradeSubscriptionModal';
import { getStorageSubscriptionName } from '../utils/storage';
import { SubscritptionName } from '../utils/subscription';
import { isExtension } from '../utils/feature';
import posthog from 'posthog-js';

type MonhtlySubsctiptionNames =
  | SubscritptionName.MONTHLY_STARTER
  | SubscritptionName.MONTHLY_PRO
  | SubscritptionName.MONTHLY_BUSINESS;

const subscriptionImportance: Record<SubscritptionName, number> = {
  [SubscritptionName.UNKNOWN]: 0,
  [SubscritptionName.FREE]: 1,
  [SubscritptionName.MONTHLY_STARTER]: 2,
  [SubscritptionName.ANNUAL_STARTER]: 2,
  [SubscritptionName.LIFETIME_STARTER]: 2,
  [SubscritptionName.APPSUMO_MONTHLY_STARTER]: 2,
  [SubscritptionName.MONTHLY_PRO]: 3,
  [SubscritptionName.ANNUAL_PRO]: 3,
  [SubscritptionName.LIFETIME_PRO]: 3,
  [SubscritptionName.MONTHLY_BUSINESS]: 4,
  [SubscritptionName.ANNUAL_BUSINESS]: 4,
  [SubscritptionName.LIFETIME_BUSINESS]: 4
};

interface UpgradeProps {
  canUseFeature: (
    subscriptionName: MonhtlySubsctiptionNames,
    allowUnregisteredUser?: boolean
  ) => boolean;
}

const UpgradeContext = createContext<UpgradeProps>({
  canUseFeature: () => false
});

interface Props {
  children: React.ReactNode;
}

export const UpgradeProvider: FC<Props> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [subscriptionName, setSubscritptionName] =
    useState<MonhtlySubsctiptionNames>(SubscritptionName.MONTHLY_STARTER);

  const canUseFeature = (
    limitSubscriptionName: MonhtlySubsctiptionNames,
    allowUnregisteredUser = false
  ): boolean => {
    if (isExtension()) return true;
    if (!posthog.isFeatureEnabled('FeatureRestriction')) return true;

    const subscriptionName = getStorageSubscriptionName();
    if (
      subscriptionName === SubscritptionName.UNKNOWN &&
      allowUnregisteredUser
    ) {
      return true;
    }

    if (
      subscriptionImportance[limitSubscriptionName] >
      subscriptionImportance[subscriptionName]
    ) {
      setIsVisible(true);
      setSubscritptionName(limitSubscriptionName);
      return false;
    }
    return true;
  };

  return (
    <UpgradeContext.Provider value={{ canUseFeature }}>
      <UpgradeSubscriptionModal
        subscriptionName={subscriptionName}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onClose={() => undefined}
      />
      {children}
    </UpgradeContext.Provider>
  );
};

export const useUpgrade = () => {
  const context = useContext(UpgradeContext);
  if (!context) {
    throw new Error('useUpgrade must be used within a LayoutProvider');
  }
  return context;
};
