import { Textarea } from 'flowbite-react';
import { useState } from 'react';
import { ProcessingLevel, SummarizationType } from '../data/summary';
import { Summary } from '../models/Summary';
import { SummaryActionMenu } from './SummaryActionMenu';
import { getYoutubeUrlFromId } from '../utils/isYoutubeUrl';

const AdvancedSummaryView: React.FC<{ summary: string }> = ({ summary }) => (
  <p className="text-base text-gray-600 text-justify">{summary}</p>
);

const BasicSummaryView: React.FC<{ summary: string }> = ({ summary }) => {
  if (!summary) return <></>;
  const keySentences = summary.split('\n');
  return (
    <ul className="list-disc text-base">
      {keySentences.map((sentence, index) => (
        <li key={index}>{sentence}</li>
      ))}
    </ul>
  );
};

interface Props {
  summary: Summary;
  removeSummary?: (summaryId: string) => void;
}

type PropsWithOptionalSummary = Omit<Props, 'summary'> &
  Partial<Pick<Props, 'summary'>>;

export const GuaranteedSummaryDisplay: React.FC<Props> = ({
  summary,
  removeSummary
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [previousSummary, setPreviousSummary] = useState<Summary>(summary);
  const [localSummary, setLocalSummary] = useState<Summary>(summary);

  const updateSummaryText = (summaryText: string) => {
    setLocalSummary(localSummary.updateSummaryText(summaryText));
  };

  const resetModifications = () => updateSummaryText(previousSummary.summary);
  const saveModifications = () => setPreviousSummary(localSummary);

  return (
    <div className="relative bg-white rounded-xl w-full">
      {(summary.type === SummarizationType.Youtube ||
        summary.type === SummarizationType.Url) &&
        summary.source && (
          <p className="text-xs mb-4 text-gray-400">
            {summary.type === SummarizationType.Youtube && (
              <>
                YouTube:{' '}
                <a
                  className="transition-colors hover:underline hover:text-blue-600"
                  href={getYoutubeUrlFromId(summary.source)}
                  target="_blank"
                  rel="noopener"
                >
                  https://www.youtube.com/watch?v={summary.source}
                </a>
              </>
            )}
            {summary.type === SummarizationType.Url && (
              <>
                URL:{' '}
                <a
                  className="transition-colors hover:underline hover:text-blue-600"
                  href={summary.source}
                  target="_blank"
                  rel="noopener"
                >
                  {summary.source}
                </a>
              </>
            )}
          </p>
        )}
      {isEditing && summary.type !== SummarizationType.Youtube ? (
        <Textarea
          className="text-justify w-full"
          value={localSummary.summary}
          rows={20}
          onChange={(e) => updateSummaryText(e.target.value)}
        />
      ) : (
        <>
          {localSummary.processingLevel === ProcessingLevel.Advanced ? (
            <AdvancedSummaryView summary={localSummary.summary} />
          ) : (
            <BasicSummaryView summary={localSummary.summary} />
          )}
        </>
      )}
      <SummaryActionMenu
        isEditAllowed={summary.type !== SummarizationType.Youtube}
        summary={localSummary}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        resetModifications={resetModifications}
        saveModifications={saveModifications}
        removeSummary={removeSummary}
      />
    </div>
  );
};

export const SummaryDisplay: React.FC<PropsWithOptionalSummary> = ({
  summary,
  removeSummary
}) => {
  if (!summary) {
    return null;
  }

  return (
    <GuaranteedSummaryDisplay summary={summary} removeSummary={removeSummary} />
  );
};
