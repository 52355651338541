import * as React from 'react';
import { isAuthentificated } from '../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import { AuthForm } from './AuthForm';
import { useTranslation } from 'react-i18next';

interface AuthenticatorTitleProps {
  reason: string | null;
}

const AuthenticatorTitle: React.FC<AuthenticatorTitleProps> = ({ reason }) => {
  const { t } = useTranslation();
  switch (reason) {
    case 'redeemCoupon':
      return (
        <div className="flex flex-col items-center mb-10 font-bold">
          <p>{t('protectedLayout.redeemCoupon.title')}</p>
          <p>{t('protectedLayout.redeemCoupon.description')}</p>
        </div>
      );
    default:
      return <></>;
  }
};

interface ProtectedLayoutProps {
  children: React.ReactNode;
  reason?: string;
}

export const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({
  children,
  reason
}) => {
  const [searchParams] = useSearchParams();
  const isSignUpPage = searchParams.get('initialState') === 'signUp';
  const messageReason = reason || searchParams.get('reason');

  if (isAuthentificated()) return <>{children}</>;
  return (
    <div className="flex flex-1 flex-col justify-center items-center p-5">
      <AuthenticatorTitle reason={messageReason} />
      <AuthForm isSignUpPage={isSignUpPage} />
    </div>
  );
};
