import { useTranslation } from 'react-i18next';
import {
  SubscriptionMapping,
  SUBSCRIPTION_MAPPING,
  SubscritptionName
} from '../../utils/subscription';

type FeatureParam = keyof SubscriptionMapping;

export type FeatureType = {
  key: string;
  params?: FeatureParam[];
};

const pricingConfig: Record<string, FeatureType[]> = {
  FREE: [
    {
      key: 'summaries',
      params: ['monthlyTokens']
    },
    {
      key: 'saved',
      params: ['savedSummaries']
    },
    {
      key: 'laguages'
    }
  ],
  STARTER: [
    {
      key: 'summaries',
      params: ['monthlyTokens']
    },
    {
      key: 'saved',
      params: ['savedSummaries']
    },
    {
      key: 'laguages'
    },
    {
      key: 'share'
    },
    {
      key: 'readLater'
    }
  ],
  PRO: [
    {
      key: 'summaries',
      params: ['monthlyTokens']
    },
    {
      key: 'saved',
      params: ['savedSummaries']
    },
    {
      key: 'laguages'
    },
    {
      key: 'share'
    },
    {
      key: 'readLater'
    },
    {
      key: 'support'
    }
  ],
  BUSINESS: [
    {
      key: 'summaries',
      params: ['monthlyTokens']
    },
    {
      key: 'saved',
      params: ['savedSummaries']
    },
    {
      key: 'laguages'
    },
    {
      key: 'share'
    },
    {
      key: 'readLater'
    },
    {
      key: 'support'
    }
  ]
};

export const getSubscritptionFeatures = (
  subscriptionName: SubscritptionName
) => {
  const features: FeatureType[] = [];

  switch (subscriptionName) {
    case SubscritptionName.FREE:
      features.push(...pricingConfig.FREE);
      break;
    case SubscritptionName.MONTHLY_STARTER:
    case SubscritptionName.ANNUAL_STARTER:
    case SubscritptionName.LIFETIME_STARTER:
      features.push(...pricingConfig.STARTER);
      break;
    case SubscritptionName.MONTHLY_PRO:
    case SubscritptionName.ANNUAL_PRO:
    case SubscritptionName.LIFETIME_PRO:
      features.push(...pricingConfig.PRO);
      break;
    case SubscritptionName.MONTHLY_BUSINESS:
    case SubscritptionName.ANNUAL_BUSINESS:
    case SubscritptionName.LIFETIME_BUSINESS:
      features.push(...pricingConfig.BUSINESS);
      break;
    default:
      break;
  }

  return features;
};

type FeatureProps = {
  feature: FeatureType;
  subscritptionName: SubscritptionName;
};

export const Feature = (props: FeatureProps) => {
  const { t } = useTranslation();
  const { subscritptionName, feature } = props;
  const { key, params } = feature;
  const featureParams: Record<string, string | number> = {};

  if (params) {
    params.forEach((param, index) => {
      featureParams[`param${index}`] =
        SUBSCRIPTION_MAPPING[subscritptionName][param];
    });
  }

  return (
    <p className="flex items-center text-gray-600 mb-2 item text-left text-base">
      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          className="w-3 h-3"
          viewBox="0 0 24 24"
        >
          <path d="M20 6L9 17l-5-5"></path>
        </svg>
      </span>
      {t(`pricing.features.${key}`, featureParams)}
    </p>
  );
};
