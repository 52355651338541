export function isObjKey<T extends object>(
  key: PropertyKey,
  obj: T
): key is keyof T {
  return key in obj;
}

export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}
