import {
  ClipboardDocumentListIcon,
  LinkIcon,
  BookmarkIcon,
  TrashIcon,
  CheckIcon,
  XMarkIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline';
import { Button } from 'flowbite-react';
import posthog from 'posthog-js';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  deleteSavedSummary,
  saveSummary,
  updateSavedSummary
} from '../api/api';
import { isAuthentificated } from '../hooks/useAuth';
import { Summary } from '../models/Summary';
import { copyToClipboard } from '../utils/text';
import { SocialShareModal } from './modals/SocialShareModal';

interface ActionButtonProps {
  onClick: () => void;
  text: string;
  children: React.ReactNode;
}

export const ActionButton: FC<ActionButtonProps> = ({
  onClick,
  text,
  children
}) => (
  <Button onClick={onClick} color="gray" className="flex w-full md:w-auto">
    <p className="text-bold mr-2">{text}</p>
    {children}
  </Button>
);

interface SummaryActionMenuProps {
  isEditAllowed: boolean;
  summary: Summary;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  resetModifications: () => void;
  saveModifications: () => void;
  removeSummary?: (summaryId: string) => void;
}

export const SummaryActionMenu: FC<SummaryActionMenuProps> = ({
  isEditAllowed = true,
  summary,
  isEditing,
  setIsEditing,
  resetModifications,
  saveModifications,
  removeSummary
}) => {
  const { t } = useTranslation();
  const [isBookmarked, setIsBookmarked] = useState<boolean>(
    summary.likeId !== undefined
  );
  const originialUrl = summary.getOriginalUrl();

  const onCopy = () => {
    posthog.capture('SummaryAction', { actionType: 'Copy' });
    toast.info(t('summary.action.copy.success'));
    copyToClipboard(summary.summary);
  };

  const onDelete = () => {
    posthog.capture('SummaryAction', { actionType: 'Delete' });
    deleteSavedSummary(summary.summaryId);
    setIsBookmarked(false);
    removeSummary && removeSummary(summary.summaryId);
  };

  const onBookmark = () => {
    posthog.capture('SummaryAction', { actionType: 'Bookmark' });
    saveSummary(summary.summaryId);
    setIsBookmarked(true);
  };

  const onStartEditing = () => {
    if (isEditAllowed) {
      posthog.capture('SummaryAction', { actionType: 'Edit' });
      setIsEditing(true);
    }
  };
  const onStopEditing = () => setIsEditing(false);

  const onSaveEdit = () => {
    if (isEditAllowed) {
      if (!summary.summary) {
        return toast.error(t('summary.action.save.error.empty'));
      }

      saveModifications();
      setIsBookmarked(true);
      onStopEditing();

      updateSavedSummary(summary);
    }
  };

  const onCancelEdit = () => {
    resetModifications();
    onStopEditing();
  };

  const onOpenLink = () => {
    posthog.capture('SummaryAction', { actionType: 'OpenLink' });
    window.open(originialUrl, '_blank');
  };

  return (
    <div className="flex flex-col gap-3 justify-end mt-4 md:flex-row">
      {isEditing ? (
        <>
          {isEditAllowed && (
            <ActionButton
              onClick={onSaveEdit}
              text={t('summary.action.save.label')}
            >
              <CheckIcon className="h-6 w-6 text-green-700" />
            </ActionButton>
          )}

          <ActionButton
            onClick={onCancelEdit}
            text={t('summary.action.cancel')}
          >
            <XMarkIcon className="h-6 w-6 text-red-700" />
          </ActionButton>
        </>
      ) : (
        <>
          {originialUrl && (
            <ActionButton onClick={onOpenLink} text={t('summary.action.open')}>
              <LinkIcon className="h-6 w-6" />
            </ActionButton>
          )}
          {isAuthentificated() && isEditAllowed && (
            <ActionButton
              onClick={onStartEditing}
              text={t('summary.action.edit')}
            >
              <PencilSquareIcon className="h-6 w-6" />
            </ActionButton>
          )}
          <ActionButton onClick={onCopy} text={t('summary.action.copy.label')}>
            <ClipboardDocumentListIcon className="h-6 w-6" />
          </ActionButton>

          {isAuthentificated() && isBookmarked && (
            <ActionButton onClick={onDelete} text={t('summary.action.unsave')}>
              <TrashIcon className="h-6 w-6 text-red-700" />
            </ActionButton>
          )}
          {isAuthentificated() && !isBookmarked && (
            <ActionButton
              onClick={onBookmark}
              text={t('summary.action.bookmark')}
            >
              <BookmarkIcon className="h-6 w-6 text-orange-400" />
            </ActionButton>
          )}
          {isAuthentificated() && summary.shareUrl && (
            <SocialShareModal summary={summary} />
          )}
        </>
      )}
    </div>
  );
};
