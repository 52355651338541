import { Button } from 'flowbite-react';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLatestSavedSummary } from '../api/api';
import { LoaderWithText } from '../components/Loader';
import NoContent from '../components/NoContent';
import { ProtectedLayout } from '../components/ProtectedLayout';
import { SummaryAccordion } from '../components/SummaryAccordion';
import { isAuthentificated, useAuth } from '../hooks/useAuth';
import { Layout, useLayout } from '../hooks/useLayout';
import { Summary } from '../models/Summary';

const Saved = () => {
  const { setLayout } = useLayout();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>();
  const user = useAuth();
  const isAuth = isAuthentificated();
  const { t } = useTranslation();

  setLayout(Layout.Saved);

  const loadSavedSummaries = () => {
    getLatestSavedSummary(nextPageToken)
      .then((res) => {
        posthog.capture('LoadSavedSummaries', {
          fetchedCount: res.summaries.length,
          totalCount: summaries.length + res.summaries.length
        });
        setSummaries(summaries.concat(res.summaries));
        setNextPageToken(res.nextPageToken);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isAuth) {
      loadSavedSummaries();
    }
  }, [user]);

  const removeSummary = (summaryId: string) => {
    const newSummaries = summaries.filter((summary) => {
      return summary.summaryId != summaryId;
    });

    setSummaries(newSummaries);
  };

  return (
    <ProtectedLayout>
      {isLoading ? (
        <LoaderWithText text={t('summary.saved.loading')} />
      ) : (
        <>
          {summaries.length === 0 ? (
            <NoContent />
          ) : (
            <div className="flex justify-center flex-wrap">
              <SummaryAccordion
                summaries={summaries}
                removeSummary={removeSummary}
              />
              <div className="flex md:flex-row justify-center py-5 shrink-0 grow-0 basis-full">
                {nextPageToken && (
                  <Button className="w-full max-w-[160px]" onClick={loadSavedSummaries}>
                    {t('summary.saved.loadMore')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </ProtectedLayout>
  );
};

export default Saved;
