import { isDevelopment, isPreProd } from './feature';
import { posthog } from 'posthog-js';
import { isObjKey } from './object';
import {
  setStorageLifetimeSubscription,
  setStorageSubscriptionName
} from './storage';

export enum SubscritptionName {
  FREE = 'FREE',
  ANNUAL_STARTER = 'ANNUAL_STARTER',
  MONTHLY_STARTER = 'MONTHLY_STARTER',
  ANNUAL_PRO = 'ANNUAL_PRO',
  MONTHLY_PRO = 'MONTHLY_PRO',
  ANNUAL_BUSINESS = 'ANNUAL_BUSINESS',
  MONTHLY_BUSINESS = 'MONTHLY_BUSINESS',
  APPSUMO_MONTHLY_STARTER = 'APPSUMO_MONTHLY_STARTER',
  LIFETIME_STARTER = 'LIFETIME_STARTER',
  LIFETIME_PRO = 'LIFETIME_PRO',
  LIFETIME_BUSINESS = 'LIFETIME_BUSINESS',
  UNKNOWN = 'UNKNOWN'
}

export interface SubscriptionMapping {
  monthlyTokens: number;
  savedSummaries: number;
}

export const SUBSCRIPTION_MAPPING: Record<
  SubscritptionName,
  SubscriptionMapping
> = {
  FREE: {
    monthlyTokens: 5,
    savedSummaries: 10
  },
  MONTHLY_STARTER: {
    monthlyTokens: 50,
    savedSummaries: 100
  },
  ANNUAL_STARTER: {
    monthlyTokens: 50,
    savedSummaries: 100
  },
  MONTHLY_PRO: {
    monthlyTokens: 250,
    savedSummaries: 100
  },
  ANNUAL_PRO: {
    monthlyTokens: 250,
    savedSummaries: 100
  },
  MONTHLY_BUSINESS: {
    monthlyTokens: 1000,
    savedSummaries: 1000
  },
  ANNUAL_BUSINESS: {
    monthlyTokens: 1000,
    savedSummaries: 1000
  },
  APPSUMO_MONTHLY_STARTER: {
    monthlyTokens: 50,
    savedSummaries: 100
  },
  LIFETIME_STARTER: {
    monthlyTokens: 50,
    savedSummaries: 100
  },
  LIFETIME_PRO: {
    monthlyTokens: 250,
    savedSummaries: 500
  },
  LIFETIME_BUSINESS: {
    monthlyTokens: 1000,
    savedSummaries: 1000
  },
  UNKNOWN: {
    monthlyTokens: 0,
    savedSummaries: 0
  }
};

const paddleProdConfig = {
  810646: SubscritptionName.ANNUAL_STARTER,
  810641: SubscritptionName.MONTHLY_STARTER,
  810644: SubscritptionName.ANNUAL_PRO,
  810643: SubscritptionName.MONTHLY_PRO,
  810645: SubscritptionName.ANNUAL_BUSINESS,
  810642: SubscritptionName.MONTHLY_BUSINESS,
  827437: SubscritptionName.LIFETIME_STARTER,
  819728: SubscritptionName.LIFETIME_PRO,
  827438: SubscritptionName.LIFETIME_BUSINESS
};

const paddleDevConfig = {
  46270: SubscritptionName.ANNUAL_STARTER,
  41983: SubscritptionName.MONTHLY_STARTER,
  46269: SubscritptionName.ANNUAL_PRO,
  43876: SubscritptionName.MONTHLY_PRO,
  46272: SubscritptionName.ANNUAL_BUSINESS,
  43426: SubscritptionName.MONTHLY_BUSINESS,
  50410: SubscritptionName.LIFETIME_STARTER,
  47337: SubscritptionName.LIFETIME_PRO,
  50411: SubscritptionName.LIFETIME_BUSINESS
};

export const isLifeTimeSubscription = (subscriptionName: SubscritptionName) =>
  subscriptionName === SubscritptionName.APPSUMO_MONTHLY_STARTER ||
  subscriptionName === SubscritptionName.LIFETIME_STARTER ||
  subscriptionName === SubscritptionName.LIFETIME_PRO ||
  subscriptionName === SubscritptionName.LIFETIME_BUSINESS;

const paddleProductConfig =
  isDevelopment() || isPreProd() ? paddleDevConfig : paddleProdConfig;

export const getSubscritptionName = (planId: string): SubscritptionName => {
  if (isObjKey(planId, paddleProductConfig)) {
    return paddleProductConfig[planId];
  }
  return SubscritptionName.UNKNOWN;
};

export const onUpdateSubscription = (subscriptionName: SubscritptionName) => {
  setStorageLifetimeSubscription(isLifeTimeSubscription(subscriptionName));
  setStorageSubscriptionName(subscriptionName);

  posthog.people.set({
    SubscriptionName: subscriptionName,
    isLifeTimeSubscription: isLifeTimeSubscription(subscriptionName)
  });
};
