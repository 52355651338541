import posthog from 'posthog-js';
import { SummaryConfiguration, SummaryProcessingStatus } from './summary';

export enum SummaryErrorReason {
  TooEarly = 'tooEarly',
  UnprocessableContent = 'UnprocessableContent',
  UnprocessableYoutubeContent = 'UnprocessableYoutubeContent',
  Unknown = 'Unknown'
}

export interface SummaryEventParams {
  statusCode?: number;
  data?: {
    reason?: SummaryErrorReason;
  };
}

const captureSummaryRequestEvent = (
  eventName: string,
  status: SummaryProcessingStatus,
  config: SummaryConfiguration,
  eventParams?: SummaryEventParams
) => {
  posthog.capture(eventName, {
    ...eventParams,
    status,
    type: config.type,
    source: config.source,
    processingLevel: config.processingLevel
  });
};

export const captureSummaryRequest = (
  status: SummaryProcessingStatus,
  config: SummaryConfiguration,
  eventParams?: SummaryEventParams
) => captureSummaryRequestEvent('SummaryRequest', status, config, eventParams);

export const captureSummaryAPIRequest = (
  status: SummaryProcessingStatus,
  config: SummaryConfiguration,
  eventParams?: SummaryEventParams
) =>
  captureSummaryRequestEvent('SummaryRequestAPI', status, config, eventParams);
