import { Button } from 'flowbite-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import {
  Feature,
  getSubscritptionFeatures
} from '../components/pricing/Feature';
import { useCheckout } from '../hooks/useCheckout';
import {
  Events,
  PaddleCheckoutState,
  PaddlePaymentCompleteEvent
} from '../utils/events';
import { getSubscritptionName, SubscritptionName } from '../utils/subscription';

export const LifetimeDeal: React.FC = () => {
  const { setSelectedProductId } = useCheckout();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const productId = searchParams.get('productId');

  const openCheckout = () => {
    setSelectedProductId?.(productId);
  };

  const onPaddlePaymentComplete = (
    event: CustomEventInit<PaddlePaymentCompleteEvent>
  ) => {
    if (event?.detail?.checkoutState === PaddleCheckoutState.PAYEMENT) {
      // navigate('/profile');
    }
  };

  useEffect(() => {
    document.addEventListener(
      Events.PaddlePaymentComplete,
      onPaddlePaymentComplete
    );
    return () => {
      setSelectedProductId?.(null);
      document.removeEventListener(
        Events.PaddlePaymentComplete,
        onPaddlePaymentComplete
      );
    };
  }, []);

  if (!productId) return <Navigate to="/" />;
  const subscriptionPlanName = getSubscritptionName(productId);
  const features = getSubscritptionFeatures(subscriptionPlanName);

  if (subscriptionPlanName === SubscritptionName.UNKNOWN) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-1 flex-col sm:mt-0 py-5 px-5 space-y-10 items-center w-full">
      <div className="flex flex-col items-center gap-y-2">
        <p className="font-bold text-2xl text-center">
          {t('lifetimeDeal.title')}
        </p>
        <p className="md:w-2/3 lg:w-5/6 xl:w-2/3 text-center text-md">
          {t('lifetimeDeal.description')}
        </p>
        <div className="py-5">
          <p className="text-center text-xl font-bold pb-2">
            {t('pricing.features.title')}
          </p>
          {features.map((feature) => (
            <Feature
              key={feature.key}
              feature={feature}
              subscritptionName={subscriptionPlanName}
            />
          ))}
        </div>
        <div className="flex justify-center w-full">
          <Button
            className="bg-blue-500 hover:bg-blue-600 px-4 self-end w-full md:w-1/3 xl:md:w-1/4"
            onClick={openCheckout}
            type="submit"
          >
            {t('lifetimeDeal.checkoutButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};
