import { UserInfo } from '../../models/UserInfo';
import { isExtension } from '../../utils/feature';
import { Pricing } from './Pricing';

interface PricingDisplayProps {
  isLifetimeSubscription: boolean;
  userData?: UserInfo;
}

export const PricingDisplay: React.FC<PricingDisplayProps> = ({
  userData,
  isLifetimeSubscription
}) => {
  return (
    <>
      {!isExtension() && !isLifetimeSubscription && (
        <div className="pt-8">
          <Pricing
            currentPlanName={userData?.planName}
            cancelUrl={userData?.cancelUrl}
          />
        </div>
      )}
    </>
  );
};
