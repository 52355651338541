import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import Creator from './views/Summarizer';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppNavbar } from './components/Navbar';
import { AuthProvider } from './hooks/useAuth';
import Profile from './views/Profile';
import Saved from './views/Saved';
import { LayoutProvider } from './hooks/useLayout';
import { PaymentProvider } from './hooks/usePayment';
import { ToastContainer } from 'react-toastify';
import { AppFooter } from './components/Footer';
import { MarkdownLoader, MarkdownPage } from './components/MarkdownLoader';
import { isExtension } from './utils/feature';
import { OutOfCredits } from './views/OutOfCredits';
import { CouponRedeem } from './views/CouponRedeem';
import { LifetimeDeal } from './views/LifetimeDeal';
import { requestUserInfo } from './api/api';
import { useEffect } from 'react';
import { UpgradeProvider } from './hooks/useUpgrade';

const MainContent = () => (
  <Routes>
    <Route path="/" element={<Creator />} />
    <Route path="/saved" element={<Saved />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/out-of-credits" element={<OutOfCredits />} />
    <Route path="/lifetime-deal" element={<LifetimeDeal />} />
    <Route path="/coupon-redeem" element={<CouponRedeem />} />
    <Route
      path="/privacy"
      element={<MarkdownLoader page={MarkdownPage.Pirvacy} />}
    />
    <Route
      path="/terms"
      element={<MarkdownLoader page={MarkdownPage.Terms} />}
    />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

const App = () => {
  if (!isExtension()) {
    window.CRISP_WEBSITE_ID = '609b87be-132d-430c-a554-738b4c166432';
  }

  // TODO Remove after 2023-05-31, it is only used to set the user's plan
  useEffect(() => {
    requestUserInfo();
  }, []);

  return (
    <div
      className={[
        'min-h-screen flex flex-col lg:container lg:mx-auto',
        isExtension() ? 'body-extension' : ''
      ].join(' ')}
    >
      <AuthProvider>
        <PaymentProvider>
          <LayoutProvider>
            <UpgradeProvider>
              <div className="flex flex-col h-screen">
                <AppNavbar />
                <MainContent />
                <AppFooter />
              </div>
            </UpgradeProvider>
          </LayoutProvider>
        </PaymentProvider>
      </AuthProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
