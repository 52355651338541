import { Amplify } from 'aws-amplify';
import awsconfigProd from '../aws-exports.prod';
import awsconfigDev from '../aws-exports.dev';
import awsconfigStaging from '../aws-exports.staging';
import { isDevelopment, isPreProd } from './feature';

const awsConfig = isDevelopment()
  ? awsconfigStaging
  : isPreProd()
  ? awsconfigDev
  : awsconfigProd;

const api = awsConfig.aws_cloud_logic_custom[0];

const updatedAwsConfig = {
  ...awsConfig,
  aws_cloud_logic_custom: [
    {
      name: api.name,
      endpoint: api.endpoint, //'http://localhost:5000',
      region: api.region
    }
  ]
};

Amplify.configure(updatedAwsConfig);
