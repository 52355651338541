import { useEffect, useState } from 'react';

export enum PaddleCallbakEvent {
  Loaded = 'Checkout.Loaded',
  Close = 'Checkout.Close',
  Complete = 'Checkout.Complete',
  Subscribed = 'Checkout.User.Subscribed',
  Change = 'Checkout.Quantity.Change',
  Login = 'Checkout.Login',
  Logout = 'Checkout.Logout',
  PaymentMethodSelected = 'Checkout.PaymentMethodSelected',
  CouponAdd = 'Checkout.Coupon.Add',
  CouponSubmit = 'Checkout.Coupon.Submit',
  CouponCancel = 'Checkout.Coupon.Cancel',
  CouponApplied = 'Checkout.Coupon.Applied',
  CouponRemove = 'Checkout.Coupon.Remove',
  Error = 'Checkout.Error',
  LocationSubmit = 'Checkout.Location.Submit',
  LanguageChange = 'Checkout.Language.Change',
  VatAdd = 'Checkout.Vat.Add',
  VatCancel = 'Checkout.Vat.Cancel',
  VatSubmit = 'Checkout.Vat.Submit',
  VatApplied = 'Checkout.Vat.Applied',
  VatRemove = 'Checkout.Vat.Remove',
  WireTransferComplete = 'Checkout.WireTransfer.Complete',
  OfflinePaymentDetailsComplete = 'Checkout.OfflinePayment.DetailsComplete',
  PaymentComplete = 'Checkout.PaymentComplete',
  PaymentMethodChange = 'Checkout.PaymentMethodChange',
  WireTransferPaymentMethodChange = 'Checkout.WireTransfer.PaymentMethodChange'
}

export interface PaddleCallback {
  event: PaddleCallbakEvent;
  checkoutData: {
    product: string;
  };
  eventData: unknown;
}

interface SetupConfig {
  vendor: number;
  eventCallback?: (data: PaddleCallback) => void;
}

export enum CheckoutMethod {
  Inline = 'inline',
  Overlay = 'overlay',
  Sdk = 'sdk'
}

export interface CheckoutConfig {
  method: CheckoutMethod;
  frameTarget?: string;
  frameStyle?: string;
  product?: number | string;
  title?: string;
  message?: string;
  coupon?: string;
  email?: string;
  marketingConsent?: '0' | '1';
  country?: string;
  postcode?: string;
  allowQuantity?: string | boolean;
  quantity?: number | string;
  disableLogout?: string | boolean;
  customData?: Record<string, unknown>;
  locale?: string;
  passthrough?: string;
  referring_domain?: string;
  success?: string;
  successCallback?: string;
  closeCallback?: string;
  loadCallback?: string;
  upsell?: string | number;
  upsellTitle?: string;
  upsellText?: string;
  upsellAction?: string;
  upsellCoupon?: string;
  upsellPassthrough?: string;
  override?: string;
  displayModeTheme?: 'light' | 'dark';
}

interface Paddle {
  Setup: (PaddleConfig: SetupConfig) => void;
  Environment: {
    set: (environment: string) => void;
  };
  Checkout: {
    open: (checkoutConfig: CheckoutConfig) => void;
  };
}

export interface UsePaddleProps extends SetupConfig {
  environment?: string;
}

interface UsePaddle {
  (props: UsePaddleProps): {
    paddle: Paddle | null;
  };
}

const usePaddle: UsePaddle = ({
  environment,
  vendor,
  eventCallback = () => undefined
}) => {
  const [paddle, setPaddle] = useState<Paddle | null>(null);
  useEffect(() => {
    if (paddle || typeof window === 'undefined') return;
    if (document.getElementById('paddle')) {
      const paddle = window.Paddle as Paddle;
      setPaddle(paddle);
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      const paddle = (window.Paddle as Paddle) || null;
      if (paddle) {
        if (environment) paddle.Environment.set(environment);
        paddle.Setup({ vendor, eventCallback });
        setPaddle(paddle);
      }
    });
  }, [environment, vendor, paddle]);
  return { paddle };
};

export default usePaddle;
