import { Footer } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { isExtension } from '../utils/feature';
import ChatbotsBanner from './ChatbotsBanner';

export const AppFooter = () => {
  const { t } = useTranslation();

  if (isExtension()) return <></>;
  return (
    <>
      <ChatbotsBanner />
      <Footer container={true} className="min-h-[104px] max-sm:min-h-[164px] mt-auto !shadow-none overflow-hidden">
        <div className="w-full text-center">
          <div className="flex w-full justify-center item-center">
            <Footer.LinkGroup className="space-x-3 sm:space-x-6 flex flex-col sm:flex-row">
              <Footer.Link href="/terms" className="m-0">
                {t('footer.terms')}
              </Footer.Link>
              <Footer.Link href="/privacy">{t('footer.privacy')}</Footer.Link>
              <Footer.Link href="mailto:hello@recapiogpt.com">
                {t('footer.contact')}
              </Footer.Link>
              <Footer.Link href="https://twitter.com/recapioGPT">
                Twitter
              </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div className="my-3" />
          <div className="w-full sm:flex sm:items-center justify-center">
            <Footer.Copyright href="/" by="RecapioGPT™" year={2023} />
          </div>
        </div>
      </Footer>
    </>
  );
};
