import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { signOut } from '../hooks/useAuth';
import { UserInfo } from '../models/UserInfo';
import { isExtension } from '../utils/feature';
import { ManageSubscriptionModal } from './modals/ManageSubscriptionModal';
import { PasswordChangeModal } from './modals/PasswordChangeModal';

interface ManageAccountProps {
  userData?: UserInfo;
}

export const ManageAccount: React.FC<ManageAccountProps> = ({ userData }) => {
  const { t } = useTranslation();
  const viewSubscriptionPlans = () => {
    window.open(`https://app.recapiogpt.com/profile`);
  };

  return (
    <div className="flex flex-col w-full items-center">
      <PasswordChangeModal />
      <Button
        color="light"
        className="px-4 mt-3 md:w-1/5 w-full"
        onClick={signOut}
      >
        {t('account.signOut')}
      </Button>
      {isExtension() ? (
        <Button
          className="bg-blue-500 hover:bg-blue-600 px-4 mt-3 md:w-1/5 w-full"
          onClick={viewSubscriptionPlans}
        >
          {t('profile.subscription.manage')}
        </Button>
      ) : (
        userData && <ManageSubscriptionModal userInfo={userData} />
      )}
    </div>
  );
};
