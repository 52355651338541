import { ShareIcon } from '@heroicons/react/24/outline';
import { Button, Modal } from 'flowbite-react';
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'next-share';
import posthog from 'posthog-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Summary } from '../../models/Summary';
import { copyToClipboard } from '../../utils/text';

const AI_ALFRED_URL = 'https://recapiogpt.com';

interface Props {
  summary: Summary;
}

enum ShareProvider {
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Whatsapp = 'whatsapp',
  Facebook = 'facebook',
  Reddit = 'reddit',
  Instapaper = 'instapaper',
  CopyLink = 'copyLink'
}
interface ShareProviderProps {
  provider: ShareProvider;
  summary: Summary;
  children: React.ReactNode;
}

const ShareWrapper: React.FC<ShareProviderProps> = ({
  provider,
  summary,
  children
}) => {
  const { t } = useTranslation();

  const url = summary.shareUrl ?? summary.getOriginalUrl() ?? AI_ALFRED_URL;
  const title = 'Summary: ' + summary.getTitle();

  const onShare = () => {
    posthog.capture('ShareSummary', {
      provider: provider
    });
    if (provider === ShareProvider.CopyLink) {
      copyToClipboard(url);
      toast.info(t('summary.action.copy.success'));
    }
  };

  switch (provider) {
    case ShareProvider.Facebook:
      return (
        <FacebookShareButton
          url={url}
          quote={title}
          hashtag={'#RecapioGPT'}
          onClick={onShare}
        >
          {children}
        </FacebookShareButton>
      );
    case ShareProvider.Reddit:
      return (
        <RedditShareButton url={url} title={title} onClick={onShare}>
          {children}
        </RedditShareButton>
      );
    case ShareProvider.Linkedin:
      return (
        <LinkedinShareButton url={url} onClick={onShare}>
          {children}
        </LinkedinShareButton>
      );
    case ShareProvider.Twitter:
      return (
        <TwitterShareButton url={url} title={title} onClick={onShare}>
          {children}
        </TwitterShareButton>
      );
    case ShareProvider.Whatsapp:
      return (
        <WhatsappShareButton
          url={url}
          title={title}
          separator=": "
          onClick={onShare}
        >
          {children}
        </WhatsappShareButton>
      );
    case ShareProvider.Instapaper:
      return (
        <InstapaperShareButton
          url={url}
          title={title}
          description={summary.metadata?.description}
          onClick={onShare}
        >
          {children}
        </InstapaperShareButton>
      );
    case ShareProvider.CopyLink:
      return <button onClick={onShare}>{children}</button>;
  }
};

const ShareButton: React.FC<ShareProviderProps> = ({
  provider,
  summary,
  children
}) => {
  return (
    <ShareWrapper provider={provider} summary={summary}>
      <div
        color="gray"
        className="text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 disabled:hover:bg-white focus:ring-blue-700 focus:text-blue-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-2 dark:disabled:hover:bg-gray-800 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg flex w-full p-2"
      >
        <p className="text-md">{children}</p>
      </div>
    </ShareWrapper>
  );
};

const ShareButtons: React.FC<{ summary: Summary }> = ({ summary }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center space-y-3">
      <ShareButton provider={ShareProvider.Facebook} summary={summary}>
        {t('modal.share.action.facebook')}
      </ShareButton>
      <ShareButton provider={ShareProvider.Reddit} summary={summary}>
        {t('modal.share.action.reddit')}
      </ShareButton>
      <ShareButton provider={ShareProvider.Linkedin} summary={summary}>
        {t('modal.share.action.linkedin')}
      </ShareButton>
      <ShareButton provider={ShareProvider.Twitter} summary={summary}>
        {t('modal.share.action.twitter')}
      </ShareButton>
      <ShareButton provider={ShareProvider.Whatsapp} summary={summary}>
        {t('modal.share.action.whatsapp')}
      </ShareButton>
      <ShareButton provider={ShareProvider.Instapaper} summary={summary}>
        {t('modal.share.action.instapaper')}
      </ShareButton>
      {summary.shareUrl && (
        <ShareButton provider={ShareProvider.CopyLink} summary={summary}>
          {t('modal.share.action.copyLink')}
        </ShareButton>
      )}
    </div>
  );
};

export const SocialShareModal: React.FC<Props> = ({ summary }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  if (isVisible) {
    posthog.capture('SummaryAction', { actionType: 'Share' });
  }

  const onDisplayModal = () => setIsVisible(true);
  const onCloseModal = () => setIsVisible(false);

  return (
    <>
      <Button
        color="gray"
        className="flex w-full md:w-auto"
        onClick={onDisplayModal}
      >
        <p className="text-bold mr-2">{t('summary.action.share')}</p>
        <ShareIcon className="h-6 w-6" />
      </Button>
      <Modal
        show={isVisible}
        position="center"
        popup={true}
        size="md"
        onClose={onCloseModal}
        dismissible={true}
      >
        <Modal.Header>{t('modal.share.title')}</Modal.Header>
        <Modal.Body>
          <ShareButtons summary={summary} />
        </Modal.Body>
      </Modal>
    </>
  );
};
