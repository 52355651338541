export const isLocalhost = (): boolean =>
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(
    window.location.hostname
  );

export const isDevelopment = () => process.env.NODE_ENV === 'development';
export const isPreProd = () => process.env.REACT_APP_TARGET_ENV === 'preprod';
export const isExtension = () => process.env.REACT_APP_PLATFORM !== 'web';

export const isChromiumBrowser = (): boolean => {
  const userAgent = window.navigator.userAgent;
  return (
    userAgent.indexOf('Chrome') !== -1 ||
    userAgent.indexOf('Edg') !== -1 ||
    userAgent.indexOf('OPR') !== -1 ||
    userAgent.indexOf('SamsungBrowser') !== -1 ||
    userAgent.indexOf('Brave') !== -1
  );
};

export const isFirefoxBrowser = (): boolean =>
  navigator.userAgent.includes('Firefox');
