import { CheckoutProvider, PassthroughData } from '../hooks/useCheckout';
import { isDevelopment, isPreProd } from '../utils/feature';
import { isAuthentificated, useAuth } from './useAuth';

export const paddleComponentId = 'paddle-wrapper';

const environmentConfig = {
  vendor: Number(process.env.REACT_APP_PUBLIC_PADDLE_VENDOR_ID),
  environment: isDevelopment() || isPreProd() ? 'sandbox' : ''
};

interface Props {
  children: React.ReactNode;
}

export const PaymentProvider: React.FC<Props> = ({ children }) => {
  const user = useAuth();
  const checkoutConfig: Record<string, unknown> = {};
  const passthroughData: PassthroughData = {
    purchaserId: '',
    referral: undefined
  };

  if (isAuthentificated()) {
    if (user?.attributes?.email) {
      checkoutConfig.email = user?.attributes?.email;
      checkoutConfig.disableLogout = true;
    }

    passthroughData.purchaserId = user?.username;
    checkoutConfig.customData = {
      purchaserId: user?.username
    };
    if (typeof window.Reflio !== 'undefined') {
      passthroughData.referral = window.Reflio.getReferralId();
    }
  }

  return (
    <CheckoutProvider
      environmentConfig={environmentConfig}
      checkoutConfig={checkoutConfig}
      passthroughData={passthroughData}
    >
      {children}
    </CheckoutProvider>
  );
};
