import { useEffect } from 'react';
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { SubscritptionName } from '../../utils/subscription';
import { pricingConfig } from '../pricing/Pricing';
import {
  DiscountType,
  PricingSubscription,
  PricingSubscriptionType
} from '../pricing/PricingSubscription';
import posthog from 'posthog-js';

type Props = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onClose: () => void;
  subscriptionName:
    | SubscritptionName.MONTHLY_STARTER
    | SubscritptionName.MONTHLY_PRO
    | SubscritptionName.MONTHLY_BUSINESS;
};

export const UpgradeSubscriptionModal: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  onClose,
  subscriptionName
}) => {
  const { t } = useTranslation();
  let discount;

  if (posthog.getFeatureFlag('AdvancedSummaryRestrictrion')) {
    discount = posthog.getFeatureFlagPayload(
      'AdvancedSummaryRestrictrion'
    ) as DiscountType;
  }

  useEffect(() => {
    if (isVisible) {
      posthog.capture('UpgradeModalOpened', {
        subscriptionName: subscriptionName
      });
    }
  }, [isVisible]);

  const onCloseModal = () => {
    setIsVisible(false);
    onClose();
  };

  const subscription = pricingConfig.subscriptions.find(
    (subscription) => subscription.monthly.name === subscriptionName
  ) as PricingSubscriptionType;

  return (
    <Modal show={isVisible} size="md" onClose={onCloseModal}>
      <Modal.Header>{t('modal.upgrade.title')}</Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {t('modal.upgrade.description')}
          </h3>
          <PricingSubscription
            subscription={subscription}
            discount={discount}
            isMonthly={true}
            cancelUrl={undefined}
            key={subscription.name}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
