import posthog, { PostHog } from 'posthog-js';
import { isDevelopment, isExtension } from './feature';

posthog.init('phc_fFGBEbaN18j6hWF7dmdh2A8XDHsdHAy22VulwfZDSm7', {
  api_host: process.env.REACT_APP_POSTHOG_HOST || 'https://app.posthog.com',
  bootstrap: {
    featureFlags: {}
  },
  loaded: (posthogConfig: PostHog) => {
    if (isDevelopment()) {
      posthogConfig.opt_out_capturing();
    }
  }
});

posthog.people.set({
  isExtension: isExtension()
});
