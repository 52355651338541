import { getUrlFromActiveTab } from '../api/extension';
import { SummaryUserConfiguration } from '../components/SummarizerConfigurator';
import { ProcessingLevel, SummarizationType } from '../data/summary';
import {
  ApiSummaryResponse,
  Summary,
  SummaryMetadata
} from '../models/Summary';
import { isExtension } from './feature';

const HASH_FUNCTION = 'SHA-256';

export const loadingTexts: string[] = [
  'Summarization is happening as we speak, so please wait patiently while we condense all the boring stuff into something much more exciting.',
  "Summarization is underway, so grab a snack and a drink and settle in for the wait. It'll be worth it, we promise!",
  "Summarization is taking place, so sit back, relax, and enjoy the wait. We promise it'll be a brief and entertaining summary of the waiting experience.",
  "Summarization is happening, and that means we all have to wait. But don't worry, it'll be over before you know it!",
  'Hold on tight, folks! Summarization is in progress and we need to wait for the magic to happen.',
  'Hang on tight folks, the summarization machine is working hard to condense all that information into a digestible form!',
  'Please hold, the summarization elves are busy creating a summary that will blow your mind!',
  'Just a moment, the summarization wizard is casting his magic to make all that text more manageable.',
  "Stay patient, the summarization process is underway and soon you'll be able to understand all that information in a fraction of the time!",
  'Hold your horses, the summarization tool is crunching numbers and cutting out the fluff.',
  'Hang in there, the summarization software is busy summarizing all of this information into a more digestible form.',
  'Summarizing is like trying to fit a novel into a tweet - it takes time, but the result is worth it.',
  'Just remember, the longer you wait for a summary, the more time you have to procrastinate on actually reading the whole thing.',
  "Summarizing is like making a salad - you have to chop and mix all the ingredients together, and it's not ready to eat until you let it sit for a bit.",
  "Waiting for a summary is like waiting for a pot of water to boil - it may seem like it's taking forever, but in the end, you'll have something tasty (or at least informative).",
  "Summarizing is like a magic trick - it may take a little time to perform, but when it's done, you'll be amazed at how much information has been condensed into just a few sentences.",
  'Waiting for a summary is like waiting for a bus - you never know how long it will take, but eventually, it will arrive.',
  'Summarizing is like cooking a gourmet meal - it takes time and skill to create something delicious and satisfying.',
  'Just remember, the longer you wait for a summary, the more time you have to come up with funny jokes to tell your friends about it.',
  "Waiting for a summary is like waiting for your food to digest - it may take a while, but in the end, you'll have all the nutrients you need to function.",
  'Summarizing is like a game of Tetris - you have to carefully fit all the pieces together to make a coherent whole.',
  "Waiting for a summary is like waiting for a jury to reach a verdict - it may take some time, but in the end, you'll have a clear answer.",
  "Summarizing is like putting together a puzzle - it may seem daunting at first, but with patience and persistence, you'll eventually see the bigger picture.",
  'Just remember, the longer you wait for a summary, the more time you have to perfect your procrastination skills.',
  "Waiting for a summary is like waiting for a traffic light to turn green - it may be frustrating, but eventually, you'll be able to move forward.",
  'Summarizing is like brewing a cup of tea - it takes time to steep and infuse the flavors, but the end result is worth the wait.',
  "Waiting for a summary is like waiting for a package to be delivered - it may take a while, but eventually, you'll have something to look forward to.",
  'Summarizing is like making a movie trailer - it takes the best parts of a longer work and condenses them into a short, exciting preview.',
  'Just remember, the longer you wait for a summary, the more time you have to brainstorm potential uses for it.',
  "Waiting for a summary is like waiting for your hair to dry - it may take a while, but in the end, you'll have something to show for it.",
  'Summarizing is like building a snowman - it takes time and effort to put all the pieces together, but the end result is worth it.',
  'Waiting for a summary is like waiting for your food to cool down - it may take a while, but it will be more enjoyable in the end.',
  'Summarizing is like making a smoothie - you have to blend all the ingredients together to create a tasty and nutritious final product.',
  'Just remember, the longer you wait for a summary, the more time you have to contemplate the mysteries of the universe.'
];

export enum SummaryProcessingStatus {
  Starting = 'Starting',
  Successful = 'Successful',
  Error = 'Error'
}
export interface SummaryConfiguration extends SummaryUserConfiguration {
  metadata: SummaryMetadata;
  processingLevel: ProcessingLevel;
  retries?: number;
}

export type CachedSummary = {
  summaries: Record<string, ApiSummaryResponse>;
} & (UrlSource | YoutubeIdSource);

interface UrlSource {
  type?: SummarizationType.Url;
  url: string;
}

interface YoutubeIdSource {
  type: SummarizationType.Youtube;
  youtube_id: string;
}

const CACHED_SUMMARIES = 'CachedSummariesV2';

const getCachedSummariesFromStorage = (): CachedSummary[] => {
  const cachedSummaryString = localStorage.getItem(CACHED_SUMMARIES) || '[]';
  return JSON.parse(cachedSummaryString);
};

const setCachedSummariesToStorage = (summaries: CachedSummary[]) => {
  localStorage.setItem(CACHED_SUMMARIES, JSON.stringify(summaries));
};

const cleanUrlQueryStrings = (url: string) => {
  return url;
};

const getHash = async (text: string) => {
  const buf = await crypto.subtle.digest(
    HASH_FUNCTION,
    new TextEncoder().encode(text)
  );
  return Array.prototype.map
    .call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2))
    .join('');
};

const doesLocalStorageItemMatches = (
  type: SummarizationType.Youtube | SummarizationType.Url,
  source: string,
  item: CachedSummary
) => {
  if (
    type === SummarizationType.Youtube &&
    item.type === SummarizationType.Youtube
  ) {
    return item.youtube_id === source;
  }

  if (
    type === SummarizationType.Url &&
    (item.type === SummarizationType.Url || !item.type)
  ) {
    return item.url === source;
  }

  return false;
};

export const setCachedSummaryToQueue = (
  type: SummarizationType.Youtube | SummarizationType.Url,
  source: string,
  advancedHash: string,
  basicSummary: Summary | undefined,
  advancedSummary: Summary
) => {
  const cachedSummaries = getCachedSummariesFromStorage();

  if (cachedSummaries.length > 20) {
    cachedSummaries.pop();
  }

  const index = cachedSummaries.findIndex((cachedSummary) => {
    return doesLocalStorageItemMatches(type, source, cachedSummary);
  });

  const cachedSummary: CachedSummary = {
    ...(type === SummarizationType.Youtube
      ? { youtube_id: source, type: SummarizationType.Youtube }
      : { url: source, type: SummarizationType.Url }),
    summaries: {
      ...(basicSummary ? { basic: basicSummary.toObject() } : null),
      [advancedHash]: advancedSummary.toObject()
    }
  };

  if (index > -1) {
    const oldCachedSummary = cachedSummaries.splice(index, 1);
    cachedSummary.summaries = {
      ...oldCachedSummary[0].summaries,
      ...cachedSummary.summaries
    };
  }

  cachedSummaries.unshift(cachedSummary);
  setCachedSummariesToStorage(cachedSummaries);
};

export const getCachedSummaryFromQueue = (
  type: SummarizationType.Youtube | SummarizationType.Url,
  source: string,
  advancedHash: string
): CachedSummary | null => {
  const cachedSummaries = getCachedSummariesFromStorage();
  for (const cachedSummary of cachedSummaries) {
    if (
      doesLocalStorageItemMatches(type, source, cachedSummary) &&
      cachedSummary.summaries[advancedHash]
    ) {
      return cachedSummary;
    }
  }
  return null;
};

interface LocalSummary {
  basicSummary?: Summary;
  advancedSummary: Summary;
}

export const getCachedSummary = async (
  config: SummaryUserConfiguration
): Promise<LocalSummary | null> => {
  const { type, source, prompt } = config;
  const activetabUrl =
    (isExtension() && (await getUrlFromActiveTab())) ||
    (type === SummarizationType.Url && source) ||
    (type === SummarizationType.Youtube && source);
  if (activetabUrl) {
    const youtubeId = type === SummarizationType.Youtube ? source : undefined;
    const advancedHash = youtubeId ? youtubeId : await getHash(prompt);
    const cachedSummary = getCachedSummaryFromQueue(
      type === SummarizationType.Youtube
        ? SummarizationType.Youtube
        : SummarizationType.Url,
      activetabUrl,
      advancedHash
    );
    if (cachedSummary) {
      return {
        ...(cachedSummary.summaries.basic
          ? { basicSummary: Summary.fromAPI(cachedSummary.summaries.basic) }
          : null),
        advancedSummary: Summary.fromAPI(cachedSummary.summaries[advancedHash])
      };
    }
  }
  return null;
};

export const setCachedSummary = async (
  config: SummaryUserConfiguration,
  basicSummary: Summary | undefined,
  advancedSummary: Summary
) => {
  const { type, source, prompt } = config;
  const activetabUrl =
    (isExtension() && (await getUrlFromActiveTab())) ||
    (type === SummarizationType.Url && source) ||
    (type === SummarizationType.Youtube && source);
  if (activetabUrl) {
    const url = cleanUrlQueryStrings(activetabUrl);
    const youtubeId = type === SummarizationType.Youtube ? source : undefined;
    const advancedHash = youtubeId ? youtubeId : await getHash(prompt);
    setCachedSummaryToQueue(
      type === SummarizationType.Youtube
        ? SummarizationType.Youtube
        : SummarizationType.Url,
      url,
      advancedHash,
      basicSummary,
      advancedSummary
    );
  }
};
