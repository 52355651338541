import { Button, Modal } from 'flowbite-react';
import { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckout } from '../../hooks/useCheckout';
import { SubscritptionStatus, UserInfo } from '../../models/UserInfo';

const SusbscriptionMessage = (
  status: SubscritptionStatus,
  endofPeriod: Moment
) => {
  const { t } = useTranslation();

  switch (status) {
    case SubscritptionStatus.Trialing:
    case SubscritptionStatus.Active:
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t('modal.manageSubscription.message.active', {
              date: endofPeriod.format('LL')
            })
          }}
        />
      );
    case SubscritptionStatus.Paused:
    case SubscritptionStatus.Deleted:
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t('modal.manageSubscription.message.canceled', {
              date: endofPeriod.format('LL')
            })
          }}
        />
      );
    case SubscritptionStatus.PastDue:
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t('modal.manageSubscription.message.past_due')
          }}
        />
      );
    default:
      return <></>;
  }
};

interface Props {
  userInfo: UserInfo;
}

export const ManageSubscriptionModal: React.FC<Props> = ({ userInfo }) => {
  const { onCancelSubscription, onUpdateSubscription } = useCheckout();
  const [visibility, setVisibility] = useState(false);
  const { i18n, t } = useTranslation();
  const language =
    i18n.resolvedLanguage.length > 2
      ? i18n.resolvedLanguage.substring(0, 2)
      : i18n.resolvedLanguage;

  const onDisplayModal = () => {
    setVisibility(true);
  };
  const onCloseModal = () => setVisibility(false);
  const onOpenCancellationForm = () => {
    if (language === 'es')
      window.open('https://forms.gle/XsV5ViPtLyAuY8hh6', '_blank');
    else window.open('https://forms.gle/8XYuUGJNoHgPLVPD6', '_blank');
  };

  return (
    <>
      {(userInfo.updateUrl ||
        userInfo.cancelUrl ||
        userInfo.status !== SubscritptionStatus.Active) && (
        <Button
          className="bg-blue-500 hover:bg-blue-600 px-4 mt-3 md:w-1/5 w-full"
          onClick={onDisplayModal}
        >
          {t('modal.manageSubscription.button')}
        </Button>
      )}
      <Modal
        show={visibility}
        position="center"
        popup={true}
        size="md"
        onClose={onCloseModal}
        dismissible={true}
      >
        <Modal.Header>{t('modal.manageSubscription.title')}</Modal.Header>
        <Modal.Body>
          {SusbscriptionMessage(userInfo.status, userInfo.endDate)}
        </Modal.Body>
        <Modal.Footer className="flex flex-col space-y-2 space-x-0">
          {onUpdateSubscription && userInfo.updateUrl && (
            <Button
              className="w-full"
              onClick={() => onUpdateSubscription(userInfo.updateUrl)}
            >
              {t('modal.manageSubscription.action.update')}
            </Button>
          )}
          {onCancelSubscription && userInfo.cancelUrl && (
            <Button
              className="text-white bg-red-700 border border-transparent hover:bg-red-800 focus:ring-4 focus:ring-red-300 disabled:hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 dark:disabled:hover:bg-red-600 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full"
              color="failure"
              onClick={() => {
                onOpenCancellationForm();
                onCancelSubscription(userInfo.cancelUrl);
              }}
            >
              {t('modal.manageSubscription.action.cancel')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
