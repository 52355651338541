import { useContext, useState, useEffect, createContext, FC } from 'react';
import { Auth, Hub } from 'aws-amplify';
import posthog from 'posthog-js';
import { createAccount } from '../api/api';
import { isExtension } from '../utils/feature';
import { loadScript } from '../utils/scripts';

const IS_USER_LOGGED_IN_KEY = 'isUserLoggedIn';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContext = createContext<any>(null);

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setupChat = (user: any) => {
  if (user && !isExtension()) {
    loadScript('https://client.crisp.chat/l.js');
    try {
      window.$crisp.push(['set', 'user:email', user.attributes.email]);
      window.$crisp.push(['set', 'user:nickname', [user.attributes.name]]);
    } catch (err) {
      console.error("Can't set user to Crisp");
    }
  }
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const [hasSignUp, setHasSignUp] = useState(false);
  const [user, setUser] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSignIn = async (data: any) => {
    localStorage.setItem(IS_USER_LOGGED_IN_KEY, 'true');
    if (hasSignUp) {
      await createAccount();
      setHasSignUp(false);
    }

    setupChat(data);

    posthog.identify(data.attributes, {
      email: data.attributes.email,
      emailVerified: data.email_verified
    });

    setUser(data);
  };

  useEffect(() => {
    const unsubscribe = Hub.listen(
      'auth',
      async ({ payload: { event, data } }) => {
        posthog.capture('Authentification', {
          event
        });
        switch (event) {
          case 'signIn':
          case 'autoSignIn':
            await onSignIn(data);
            break;
          case 'signOut':
            setUser(null);
            break;
          case 'signUp':
            posthog.identify(data.userSub, {
              email: data.user.username,
              emailVerified: data.userConfirmed,
              SubscriptionName: 'FREE'
            });
            break;
          case 'confirmSignUp':
            setHasSignUp(true);
            try {
              await window.Reflio.signup(data.user.username);
            } catch (err) {
              console.error('Reflio SignUp is not possible');
            }
            posthog.register({ emailVerified: true });
            break;
        }
      }
    );

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setupChat(currentUser);
        setUser(currentUser);
      })
      .catch(() => console.log('Not signed in'));

    return unsubscribe;
  }, [hasSignUp]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const signOut = async () => {
  localStorage.removeItem(IS_USER_LOGGED_IN_KEY);
  await Auth.signOut();
};

export const useAuth = () => useContext(AuthContext);
export const isAuthentificated = () => useAuth() != null;
export const isLoggedInCached = () =>
  localStorage.getItem(IS_USER_LOGGED_IN_KEY) === 'true';
