import posthog from 'posthog-js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCheckout } from '../../hooks/useCheckout';
import { SubscritptionName } from '../../utils/subscription';
import { Feature, getSubscritptionFeatures } from './Feature';

type SubscriptionConfigType = {
  name: SubscritptionName;
  isCurrent: boolean;
  price: number;
  id: string;
};

export type DiscountType = {
  coupon: string;
  discount: number;
};

export type PricingSubscriptionType = {
  monthly: SubscriptionConfigType;
  annually: SubscriptionConfigType;
  name: string;
  isPopular: boolean;
  display: boolean;
  discount?: DiscountType;
};
type PricingSubscriptionProps = {
  subscription: PricingSubscriptionType;
  isMonthly: boolean;
  discount?: DiscountType;
  cancelUrl?: string;
};

export const PricingSubscription = (props: PricingSubscriptionProps) => {
  const { onCancelSubscription, setSelectedProductId, setDiscountCoupon } =
    useCheckout();
  const { t } = useTranslation();

  const subscriptionConfig = props.isMonthly
    ? props.subscription.monthly
    : props.subscription.annually;

  const onBuy = () => {
    if (subscriptionConfig.isCurrent) {
      toast.info('You are already subscribed');
    } else if (subscriptionConfig.name === SubscritptionName.FREE) {
      onCancelSubscription &&
        props.cancelUrl &&
        onCancelSubscription(props.cancelUrl);
    } else {
      posthog.capture('Subscription', { selected: subscriptionConfig.name });
      setDiscountCoupon?.(props.discount?.coupon);
      setSelectedProductId?.(subscriptionConfig.id);
    }
  };

  const features = getSubscritptionFeatures(subscriptionConfig.name);

  let displayPrice = subscriptionConfig.price;
  if (props.discount) {
    displayPrice =
      Math.round(subscriptionConfig.price * (100 - props.discount.discount)) /
      100;
  }
  if (!props.subscription.display) return <></>;
  return (
    <div className="p-2 w-auto">
      <div
        className={`h-full p-6 rounded-lg border-2 flex flex-col relative overflow-hidden ${
          subscriptionConfig.isCurrent
            ? 'border-green-500'
            : props.discount
            ? 'border-red-500'
            : props.subscription.isPopular
            ? 'border-blue-500'
            : 'border-gray-300'
        }`}
      >
        {subscriptionConfig.isCurrent ? (
          <span className="bg-green-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
            {t('pricing.current')}
          </span>
        ) : props.discount ? (
          <span className="bg-red-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
            {props.discount.discount}% {t('pricing.discount.off')}
          </span>
        ) : (
          props.subscription.isPopular && (
            <span className="bg-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
              {t('pricing.popular')}
            </span>
          )
        )}
        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
          {props.subscription.name}
        </h2>
        <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
          <span>${displayPrice}</span>
          <span className="text-lg ml-1 font-normal text-gray-500">/mo</span>
        </h1>
        {features.map((feature) => (
          <Feature
            key={feature.key}
            feature={feature}
            subscritptionName={subscriptionConfig.name}
          />
        ))}
        {!subscriptionConfig.isCurrent && (
          <button
            onClick={onBuy}
            className={`flex items-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none rounded ${
              props.discount
                ? 'bg-red-500 hover:bg-red-600'
                : props.subscription.isPopular
                ? 'bg-blue-500 hover:bg-blue-600'
                : 'bg-gray-400 hover:bg-gray-500'
            } ${
              !props.cancelUrl &&
              subscriptionConfig.name == SubscritptionName.FREE &&
              'disable'
            }`}
          >
            {t('pricing.subscribe')}
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-auto"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
