import { Tabs } from 'flowbite-react';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestSummary } from '../api/api';
import { getMetadata, getUrlFromActiveTab } from '../api/extension';
import { capturePromptConfiguration } from '../components/AdvancedSummarizerConfigurator';
import { LoaderWithText } from '../components/Loader';
import NoContent from '../components/NoContent';
import { OutOfCreditsReason } from './OutOfCredits';
import {
  SummarizerConfigurator,
  SummaryUserConfiguration
} from '../components/SummarizerConfigurator';
import { SummaryDisplay } from '../components/SummaryDisplay';
import { ProcessingLevel, SummarizationType } from '../data/summary';
import { isAuthentificated } from '../hooks/useAuth';
import { Layout, useLayout } from '../hooks/useLayout';
import { Summary, SummaryMetadata } from '../models/Summary';
import { isExtension } from '../utils/feature';
import { SummaryErrorReason, SummaryEventParams } from '../utils/metrics';
import {
  getCachedSummary,
  loadingTexts,
  setCachedSummary,
  SummaryConfiguration,
  SummaryProcessingStatus
} from '../utils/summary';
import { normalizeSummaryRequestSource } from '../utils/normalizeSummaryRequestSource';

enum SummarizationState {
  PendingConfiguration = 'PendingConfiguration',
  LoadingBasic = 'LoadingBasic',
  ErrorBasic = 'ErrorBasic',
  LoadingAdvanced = 'LoadingAdvanced',
  ErrorAdvanced = 'ErrorAdvanced',
  GenerationDone = 'GenerationDone'
}

const UNAUTH_TOKENS = '3';
const UNAUTH_TOKENS_KEY = 'LocalTokens';

const getLocalTokens = () =>
  parseInt(localStorage.getItem(UNAUTH_TOKENS_KEY) || UNAUTH_TOKENS);
const setLocalTokens = (tokens: number) =>
  localStorage.setItem(UNAUTH_TOKENS_KEY, tokens.toString());
const onUseLocalTokens = () => {
  posthog.capture('useLocalTokens');
  setLocalTokens(getLocalTokens() - 1);
};

export const Creator = () => {
  const { setLayout } = useLayout();
  const [summarizationState, setSummarizationState] =
    useState<SummarizationState>(SummarizationState.PendingConfiguration);
  const [basicSummary, setBasicSummary] = useState<Summary>();
  const [advancedSummary, setAdvancedSummary] = useState<Summary>();
  const [loadingTextIndex, setLoadingTextIndex] = useState<number>(0);
  const [isInitialSummary, setIsInitialSummary] = useState(true);
  const [errorReason, setErrorReason] = useState<SummaryErrorReason>();
  const isUserAuthentificated = isAuthentificated();
  const [activeSummarizationTypeTab, setActiveSummarizationTypeTab] =
    useState<SummarizationType>(SummarizationType.Url);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [currentGeneratedContentType, setCurrentGeneratedContentType] =
    useState<SummarizationType>(SummarizationType.Text);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSummarizationTypeTabChange = useCallback(
    (tabId: SummarizationType) => {
      setActiveSummarizationTypeTab(tabId);
    },
    [setActiveSummarizationTypeTab]
  );

  const resetLoading = () => {
    loadingTexts.sort(() => Math.random() - 0.5);
    setSummarizationState(SummarizationState.PendingConfiguration);
    setAdvancedSummary(undefined);
    setBasicSummary(undefined);
    setErrorReason(undefined);
    setActiveTab(0);
    setLoadingTextIndex(0);
  };

  const outOfTokenErrorMessage = () => {
    navigate(
      `/out-of-credits?reason=${
        isUserAuthentificated
          ? OutOfCreditsReason.outOfCredits
          : OutOfCreditsReason.outOfFreeCredits
      }`
    );
  };

  const outOfTokenError = () => {
    posthog.capture('ReachTokenLimit', {
      authenticatedUser: isUserAuthentificated
    });
    outOfTokenErrorMessage();
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const attemptRetryWithUrl = async (config: SummaryConfiguration) => {
    const activetabUrl = await getUrlFromActiveTab();
    if (activetabUrl) {
      lazyloadingSummaries({
        ...config,
        type: SummarizationType.Url,
        source: activetabUrl
      });
    }
  };

  const requestSummaryWithRetries = async (
    config: SummaryConfiguration
  ): Promise<Summary | undefined> => {
    const retries = config.retries || 0;
    return requestSummary(config).catch(async (error) => {
      if (
        // 504 Gateway Timeout || 425 Too Early
        (error?.response?.status === 504 || error?.response?.status === 425) &&
        retries < 10
      ) {
        await delay(3000);
        return requestSummaryWithRetries({
          ...config,
          retries: retries + 1
        });
      } else if (
        // 413 Content Too Large
        isExtension() &&
        config.processingLevel === ProcessingLevel.Basic &&
        config.type === SummarizationType.Text &&
        error?.response?.status === 413
      ) {
        attemptRetryWithUrl(config);
        return undefined;
      }

      onSummaryRequestEvent(config, SummaryProcessingStatus.Error, {
        statusCode: error?.response?.status,
        data: error?.response?.data
      });
      return undefined;
    });
  };

  const useLocalSummary = async (config: SummaryUserConfiguration) => {
    const cachedSummary = await getCachedSummary(config);
    if (!cachedSummary) return false;
    setBasicSummary(cachedSummary.basicSummary);
    setAdvancedSummary(cachedSummary.advancedSummary);
    setSummarizationState(SummarizationState.GenerationDone);
    return true;
  };

  const captureSummaryRequest = (
    status: SummaryProcessingStatus,
    config: SummaryConfiguration,
    eventParams?: SummaryEventParams
  ) => {
    posthog.capture('SummaryRequest', {
      ...eventParams,
      status,
      type: config.type,
      source: config.source,
      processingLevel: config.processingLevel,
      errorReason: eventParams?.data?.reason
    });
  };

  const onBasicSummaryRequestEvent = (
    status: SummaryProcessingStatus,
    config: SummaryConfiguration,
    eventParams?: SummaryEventParams
  ) => {
    if (status === SummaryProcessingStatus.Starting) {
      setSummarizationState(SummarizationState.LoadingBasic);
    } else if (status === SummaryProcessingStatus.Error) {
      setSummarizationState(SummarizationState.ErrorBasic);
      if (eventParams?.statusCode === 403) outOfTokenError();
      if (eventParams?.data) {
        setErrorReason(eventParams.data.reason);
      }
    }
    captureSummaryRequest(status, config, eventParams);
  };

  const onAdvancedSummaryRequestEvent = (
    status: SummaryProcessingStatus,
    config: SummaryConfiguration,
    eventParams?: SummaryEventParams
  ) => {
    if (status === SummaryProcessingStatus.Starting) {
      setSummarizationState(SummarizationState.LoadingAdvanced);
    } else if (status === SummaryProcessingStatus.Error) {
      toast.error(t('summary.generation.error'));
      setSummarizationState(SummarizationState.ErrorAdvanced);
      if (eventParams?.statusCode === 403) outOfTokenError();
    } else if (status === SummaryProcessingStatus.Successful) {
      toast.success(t('summary.generation.success'));
      if (!isUserAuthentificated) onUseLocalTokens();
      setSummarizationState(SummarizationState.GenerationDone);
    }
    captureSummaryRequest(status, config, eventParams);
  };

  const onSummaryRequestEvent = (
    config: SummaryConfiguration,
    status: SummaryProcessingStatus,
    eventParams?: SummaryEventParams
  ) =>
    config.processingLevel === ProcessingLevel.Basic
      ? onBasicSummaryRequestEvent(status, config, eventParams)
      : onAdvancedSummaryRequestEvent(status, config, eventParams);

  const getSummaryConfig = (
    config: SummaryUserConfiguration,
    metadata: SummaryMetadata,
    processingLevel: ProcessingLevel
  ): SummaryConfiguration => ({
    ...config,
    metadata,
    processingLevel
  });

  const lazyloadingSummaries = async (config: SummaryUserConfiguration) => {
    const metadata = await getMetadata(config);
    const basicConfig = getSummaryConfig(
      config,
      metadata,
      ProcessingLevel.Basic
    );
    const advancedConfig = getSummaryConfig(
      config,
      metadata,
      ProcessingLevel.Advanced
    );

    let basicSummaryScoped = undefined;

    /**
     * Basic summarization is done only for non-youtube sources
     */
    if (config.type !== SummarizationType.Youtube) {
      onSummaryRequestEvent(basicConfig, SummaryProcessingStatus.Starting);
      basicSummaryScoped = await requestSummaryWithRetries(basicConfig);
      setBasicSummary(basicSummaryScoped);

      if (!basicSummaryScoped) {
        return;
      }

      onSummaryRequestEvent(basicConfig, SummaryProcessingStatus.Successful);
    }

    onSummaryRequestEvent(advancedConfig, SummaryProcessingStatus.Starting);
    const advancedSummary = await requestSummaryWithRetries(advancedConfig);
    setAdvancedSummary(advancedSummary);

    if (
      (config.type !== SummarizationType.Youtube && !basicSummaryScoped) ||
      !advancedSummary
    ) {
      return;
    }

    setCachedSummary(config, basicSummaryScoped, advancedSummary);
    onSummaryRequestEvent(advancedConfig, SummaryProcessingStatus.Successful);
  };

  const generateSummary = async (config: SummaryUserConfiguration) => {
    setIsInitialSummary(false);
    resetLoading();

    setCurrentGeneratedContentType(config.type);

    const usedLocalSummary = await useLocalSummary(
      normalizeSummaryRequestSource(config)
    );

    if (!usedLocalSummary) {
      if (!isUserAuthentificated && getLocalTokens() <= 0) {
        return outOfTokenError();
      }

      capturePromptConfiguration();
      lazyloadingSummaries(normalizeSummaryRequestSource(config));
    }
  };

  useEffect(() => {
    setLayout(Layout.Creator);
  }, []);

  const isAdvancedProcess =
    summarizationState === SummarizationState.LoadingAdvanced ||
    summarizationState === SummarizationState.ErrorAdvanced ||
    summarizationState === SummarizationState.GenerationDone;

  const summarizationFailed =
    (currentGeneratedContentType !== SummarizationType.Youtube &&
      summarizationState === SummarizationState.ErrorBasic) ||
    summarizationState === SummarizationState.ErrorAdvanced;

  return (
    <SummaryDisplayState
      currentGeneratedContentType={currentGeneratedContentType}
      summarizationState={summarizationState}
      loadingTextIndex={loadingTextIndex}
      isAdvancedProcess={isAdvancedProcess}
      basicSummary={basicSummary}
      errorReason={errorReason}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      advancedSummary={advancedSummary}
    >
      <SummarizerConfigurator
        generateSummary={generateSummary}
        summarizationFailed={summarizationFailed}
        hidden={summarizationState === SummarizationState.LoadingBasic}
        isInitialSummary={isInitialSummary}
        activeSummarizationTypeTab={activeSummarizationTypeTab}
        setActiveSummarizationTypeTabChange={onSummarizationTypeTabChange}
      />
    </SummaryDisplayState>
  );
};

function SummaryDisplayState({
  children,
  currentGeneratedContentType,
  summarizationState,
  loadingTextIndex,
  isAdvancedProcess,
  basicSummary,
  errorReason,
  setActiveTab,
  activeTab,
  advancedSummary
}: {
  children: React.ReactNode;
  currentGeneratedContentType: SummarizationType;
  summarizationState: SummarizationState;
  loadingTextIndex: number;
  isAdvancedProcess: boolean;
  basicSummary: Summary | undefined;
  errorReason: SummaryErrorReason | undefined;
  setActiveTab: (tabIndex: number) => void;
  activeTab: number;
  advancedSummary: Summary | undefined;
}) {
  const { t } = useTranslation();
  const isGeneratedYoutubeType =
    currentGeneratedContentType === SummarizationType.Youtube;

  if (summarizationState === SummarizationState.PendingConfiguration)
    return <div className="md:py-10">{children}</div>;
  else if (
    summarizationState === SummarizationState.LoadingBasic ||
    (isGeneratedYoutubeType &&
      summarizationState === SummarizationState.LoadingAdvanced)
  ) {
    return (
      <div className="px-5 flex flex-1">
        <LoaderWithText
          text={
            <>
              {loadingTexts[loadingTextIndex]}
              {isGeneratedYoutubeType && (
                <span className="block text-base mt-2">
                  Summarizing a YouTube video takes about 20 seconds.
                </span>
              )}
            </>
          }
        />
      </div>
    );
  } else if (
    !isGeneratedYoutubeType &&
    (summarizationState === SummarizationState.ErrorBasic ||
      (isAdvancedProcess && !basicSummary))
  )
    return (
      <>
        <NoContent reason={errorReason} />
        {children}
      </>
    );
  else if (isAdvancedProcess && (basicSummary || isGeneratedYoutubeType))
    return (
      <>
        {children}
        <div className="flex flex-col flex-1 mx-auto items-center w-full lg:w-5/6 xl:w-2/3">
          <div className="p-3 w-full">
            {isGeneratedYoutubeType ? (
              <Tabs.Group
                aria-label="Summary tabs"
                style="underline"
                onActiveTabChange={(tab) => setActiveTab(tab)}
              >
                <Tabs.Item title={t('summary.tabs.summary')} active>
                  {summarizationState ===
                    SummarizationState.LoadingAdvanced && (
                    <div className="h-64 flex flex-1">
                      <LoaderWithText text={t('summary.generation.loading')} />
                    </div>
                  )}
                  {(summarizationState === SummarizationState.ErrorAdvanced ||
                    (summarizationState === SummarizationState.GenerationDone &&
                      !advancedSummary)) && <NoContent />}
                  {summarizationState === SummarizationState.GenerationDone &&
                    advancedSummary && (
                      <SummaryDisplay summary={advancedSummary} />
                    )}
                </Tabs.Item>
              </Tabs.Group>
            ) : (
              <Tabs.Group
                aria-label="Summary tabs"
                style="underline"
                onActiveTabChange={(tab) => setActiveTab(tab)}
              >
                <Tabs.Item
                  title={t('summary.tabs.keySentence')}
                  active={activeTab === 0}
                >
                  <SummaryDisplay summary={basicSummary} />
                </Tabs.Item>
                <Tabs.Item
                  title={t('summary.tabs.summary')}
                  active={activeTab === 1}
                >
                  {summarizationState ===
                    SummarizationState.LoadingAdvanced && (
                    <div className="h-64 flex flex-1">
                      <LoaderWithText text={t('summary.generation.loading')} />
                    </div>
                  )}
                  {(summarizationState === SummarizationState.ErrorAdvanced ||
                    (summarizationState === SummarizationState.GenerationDone &&
                      !advancedSummary)) && <NoContent />}
                  {summarizationState === SummarizationState.GenerationDone &&
                    advancedSummary && (
                      <SummaryDisplay summary={advancedSummary} />
                    )}
                </Tabs.Item>
              </Tabs.Group>
            )}
          </div>
          <div className="flex justify-end"></div>
        </div>
      </>
    );
  else throw new Error('SummaryDisplayState is undefined');
}

export default Creator;
