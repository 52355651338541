import { Dropdown } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { onLanguageChange } from '../utils/i18n';

const lngs = [
  { key: 'de', nativeName: 'Deutsch' },
  { key: 'en', nativeName: 'English' },
  { key: 'es', nativeName: 'Español' },
  { key: 'fr', nativeName: 'Français' },
  { key: 'it', nativeName: 'Italiano' },
  { key: 'pl', nativeName: 'Polski' },
  { key: 'tr', nativeName: 'Türkçe' }
];

export const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const language =
    i18n.resolvedLanguage.length > 2
      ? i18n.resolvedLanguage.substring(0, 2)
      : i18n.resolvedLanguage;
  const selectedLanguage = lngs.find((lng) => lng.key === language);

  return (
    <Dropdown label={selectedLanguage?.nativeName} color="gray">
      {lngs.map((lng) => (
        <Dropdown.Item key={lng.key} onClick={() => onLanguageChange(lng.key)}>
          {lng.nativeName}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};
