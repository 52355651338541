export const ChatbotsLogo = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="147" height="26" viewBox="0 0 147 26" fill="#151824">
        <path d="M6.27981 12.5378C6.27981 14.2677 4.87387 15.6704 3.13996 15.6704C1.40594 15.6704 0 14.2676 0 12.5378C0 10.808 1.40594 9.40528 3.13996 9.40528C6.6001 9.40528 9.40411 6.61561 9.42755 3.17143V3.09355C9.45099 1.37916 10.8492 0 12.5675 0H21.9952C23.7291 0 25.1272 1.40254 25.1272 3.13254C25.1272 4.86244 23.7213 6.26498 21.9873 6.26498H12.5597C9.0917 6.26498 6.27981 9.07802 6.27981 12.5378Z"/>
        <path d="M21.9952 9.40528C21.9938 9.40528 21.9925 9.40528 21.9912 9.40528C23.7234 9.40741 25.1272 10.8092 25.1272 12.5378C25.1272 14.2677 23.7213 15.6704 21.9873 15.6704C18.5194 15.6704 15.7075 18.4833 15.7075 21.9431C15.7075 23.673 14.3015 25.0756 12.5675 25.0756H3.13996C1.40594 25.0756 0 23.673 0 21.9431C0 20.2132 1.40594 18.8107 3.13996 18.8107H12.5832C16.0511 18.8028 18.8552 15.9976 18.8552 12.5378C18.8552 10.8092 20.259 9.40741 21.9912 9.40528C21.9899 9.40528 21.9886 9.40528 21.9873 9.40528H21.9952Z"/>
        <path d="M35.4066 19.9888L40.8289 5.44611H44.0279L49.4275 19.9888H45.9109L44.8446 16.994H39.9441L38.8551 19.9888H35.4066ZM40.897 14.2262H43.8917L43.3246 12.6607C42.9842 11.6625 42.4624 9.91554 42.4397 9.91554C42.4624 9.91554 41.8726 11.6625 41.4869 12.6607L40.897 14.2262Z"/>
        <path d="M54.482 19.9888H51.1696V5.44611H54.482V19.9888Z"/>
        <path d="M68.4456 20.261C64.1803 20.261 61.2763 17.1982 61.2763 12.7288C61.2763 8.25936 64.1803 5.17386 68.4456 5.17386C71.6899 5.17386 74.367 6.94348 75.093 9.66598H73.5956C72.9604 7.85098 70.9866 6.44436 68.4229 6.44436C65.0424 6.44436 62.6603 8.91729 62.6603 12.7288C62.6603 16.5176 65.0424 18.9905 68.4229 18.9905C70.8504 18.9905 72.8923 17.7427 73.5956 15.7689H75.093C74.4124 18.446 71.7579 20.261 68.4456 20.261Z"/>
        <path d="M77.601 19.9888V4.42517H78.9169V11.5944C79.5295 10.5054 80.8453 9.82479 82.2066 9.82479C84.3392 9.82479 85.882 11.1634 85.882 13.8859V19.9888H84.5661V14.1581C84.5661 11.7986 83.4544 10.9819 82.0024 10.9819C80.2101 10.9819 78.8942 12.1843 78.8942 13.9539V19.9888H77.601Z"/>
        <path d="M97.3544 10.097H98.4207V19.9888H97.2183V17.9015C96.6057 19.2628 95.131 20.261 93.2026 20.261C90.4574 20.261 88.5063 18.1965 88.5063 15.0429C88.5063 11.8894 90.5028 9.82479 93.2706 9.82479C95.131 9.82479 96.6284 10.7096 97.241 12.0935L97.3544 10.097ZM93.4295 19.104C95.6075 19.104 97.0595 17.4932 97.0595 14.9749C97.0595 12.5246 95.6301 10.9819 93.4748 10.9819C91.2515 10.9819 89.8221 12.638 89.8221 15.0429C89.8221 17.4932 91.2741 19.104 93.4295 19.104Z"/>
        <path d="M105.969 10.097V11.1634H103.973V19.9888H102.679V11.1634H100.706V10.097H102.679V7.44261H103.973V10.097H105.969Z"/>
        <path d="M113.526 9.82479C116.294 9.82479 118.29 11.8894 118.29 15.0429C118.29 18.1965 116.294 20.261 113.549 20.261C111.666 20.261 110.168 19.2855 109.533 17.9015L109.397 19.9888H108.353V4.42517H109.601V12.0255C110.236 10.6415 111.779 9.82479 113.526 9.82479ZM113.367 19.104C115.522 19.104 116.974 17.4932 116.974 15.0429C116.974 12.6154 115.545 10.9819 113.344 10.9819C111.189 10.9819 109.76 12.5246 109.76 14.9749C109.76 17.4705 111.189 19.104 113.367 19.104Z"/>
        <path d="M120.278 15.0656C120.278 12.1616 122.433 9.82479 125.518 9.82479C128.627 9.82479 130.782 12.1616 130.782 15.0656C130.782 17.9469 128.604 20.261 125.518 20.261C122.456 20.261 120.278 17.9469 120.278 15.0656ZM121.616 15.0656C121.616 17.357 123.227 19.104 125.518 19.104C127.833 19.104 129.443 17.357 129.443 15.0656C129.443 12.7515 127.833 10.9819 125.518 10.9819C123.227 10.9819 121.616 12.7288 121.616 15.0656Z"/>
        <path d="M137.563 10.097V11.1634H135.567V19.9888H134.273V11.1634H132.3V10.097H134.273V7.44261H135.567V10.097H137.563Z"/>
        <path d="M143.188 20.261C140.965 20.261 139.422 19.104 139.332 17.3344H140.579C140.67 18.5368 141.714 19.2174 143.188 19.2174C144.731 19.2174 145.661 18.5595 145.661 17.4024C145.661 16.1546 144.595 15.8143 143.846 15.6782L142.009 15.2698C140.284 14.8841 139.604 13.8859 139.604 12.6607C139.604 10.9592 141.033 9.82479 143.143 9.82479C145.298 9.82479 146.75 11.0272 146.796 12.7969H145.548C145.503 11.549 144.527 10.8684 143.143 10.8684C141.782 10.8684 140.92 11.4583 140.92 12.57C140.92 13.7497 141.941 14.022 142.576 14.1581L144.618 14.6119C146.274 14.9975 147 16.0412 147 17.3117C147 19.1267 145.525 20.261 143.188 20.261Z"/>
      </svg>
  );
};

export default ChatbotsLogo;
