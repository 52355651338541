import { Spinner } from 'flowbite-react';
import { FC, ReactNode } from 'react';

export const Loader = () => (
  <div className="flex flex-1 justify-center items-center">
    <Spinner size="xl" />
  </div>
);

export const LoaderWithText: FC<{ text: ReactNode }> = ({ text }) => (
  <div className="flex flex-1 flex-col content-between justify-center w-full">
    <Loader />
    <div className="flex flex-1 justify-center w-full">
      <p className="text-xl text-justify max-w-xl">{text}</p>
    </div>
  </div>
);
