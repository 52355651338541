export enum SummarizationType {
  Url = 'Url',
  Youtube = 'Youtube',
  Text = 'Text',
  File = 'File',
  Unknown = 'Unknown'
}
export enum ProcessingLevel {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Unknown = 'Unknown'
}
