import { enumFromStringValue } from './object';
import { SubscritptionName } from './subscription';

export const IS_LIFETIME_SUBSCRIPTION = 'isLifetimeSubscription';
export const LIFETIME_SUBSCRIPTION_NAME = 'lifetimeSubscriptionType';

export const isStorageLifeTimeSubscription = () => {
  const isLifetimeSubscription = localStorage.getItem(IS_LIFETIME_SUBSCRIPTION);
  return isLifetimeSubscription === 'true';
};

export const setStorageLifetimeSubscription = (
  isLifetimeSubscription: boolean
) => {
  if (isLifetimeSubscription) {
    localStorage.setItem(
      IS_LIFETIME_SUBSCRIPTION,
      isLifetimeSubscription?.toString()
    );
  } else {
    localStorage.removeItem(IS_LIFETIME_SUBSCRIPTION);
  }
};

export const getStorageSubscriptionName = (): SubscritptionName => {
  const localSubscriptionName = localStorage.getItem(
    LIFETIME_SUBSCRIPTION_NAME
  );
  return localSubscriptionName
    ? enumFromStringValue(SubscritptionName, localSubscriptionName) ||
        SubscritptionName.UNKNOWN
    : SubscritptionName.UNKNOWN;
};

export const setStorageSubscriptionName = (subscriptionName: string) => {
  if (subscriptionName) {
    localStorage.setItem(LIFETIME_SUBSCRIPTION_NAME, subscriptionName);
  } else {
    localStorage.removeItem(LIFETIME_SUBSCRIPTION_NAME);
  }
};
