import React, { useState } from 'react';
import { ProtectedLayout } from '../components/ProtectedLayout';
import { Button, Label, TextInput } from 'flowbite-react';
import { redeemCoupon } from '../api/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setStorageLifetimeSubscription } from '../utils/storage';

export const CouponRedeem: React.FC = () => {
  const [coupon, setCoupon] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await redeemCoupon(coupon);
      setStorageLifetimeSubscription(true);
      toast.success('Your account has been updated successfully!');
      navigate('/profile');
    } catch (error) {
      toast.error('The coupon used is invalid or already used!');
    }
  };

  return (
    <ProtectedLayout reason="redeemCoupon">
      <div className="flex flex-col sm:mt-0 py-5 px-5 space-y-10 items-center">
        <div className="flex flex-col items-center gap-y-2">
          <p className="font-bold text-2xl text-center">
            {t('couponRedeem.title')}
          </p>
          <p className="md:w-2/3 lg:w-5/6 xl:w-2/3 text-center text-md">
            {t('couponRedeem.description')}
          </p>
        </div>

        <form
          className="flex w-full lg:w-5/6 xl:w-2/3 flex-col gap-4 items-center"
          onSubmit={handleSubmit}
        >
          <div className="w-full">
            <div className="mb-2 block">
              <Label
                htmlFor="couponCode"
                value={t('couponRedeem.form.coupon.label') || 'Coupon'}
              />
            </div>
            <TextInput
              id="couponCode"
              type="text"
              required={true}
              className="appearance-none border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder={
                t('couponRedeem.form.coupon.placeholder') ||
                'Example: kW421ckgr7'
              }
              value={coupon}
              onChange={(event) => setCoupon(event.target.value)}
            />
          </div>
          <Button
            className="bg-blue-500 hover:bg-blue-600 px-4 self-end w-full md:w-1/3 xl:md:w-1/4"
            type="submit"
          >
            {t('couponRedeem.form.button')}
          </Button>
        </form>
      </div>
    </ProtectedLayout>
  );
};
