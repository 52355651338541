import { Accordion } from 'flowbite-react';
import { Summary } from '../models/Summary';
import { SummaryDisplay } from './SummaryDisplay';

interface SummaryAccordionProps {
  summaries: Summary[];
  removeSummary?: (summaryId: string) => void;
}

export const SummaryAccordion: React.FC<SummaryAccordionProps> = ({
  summaries,
  removeSummary
}) => {
  summaries = summaries.filter((summary) => summary.summary);
  return (
    <Accordion flush={true} alwaysOpen={true} className="w-full lg:w-5/6">
      {summaries.map((summary) => (
        <Accordion.Panel key={summary.summaryId}>
          <Accordion.Title>
            <p className="text-base">{summary.getTitle()}</p>
          </Accordion.Title>
          <Accordion.Content>
            <SummaryDisplay
              summary={summary}
              key={summary.summaryId}
              removeSummary={removeSummary}
            />
          </Accordion.Content>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};
