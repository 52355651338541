import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Privacy from '../resources/markdown/Privacy.md';
import Terms from '../resources/markdown/Terms.md';

export enum MarkdownPage {
  Pirvacy = 'Pirvacy',
  Terms = 'Terms'
}

const getAssociatedMarkdown = (page: MarkdownPage): RequestInfo => {
  switch (page) {
    case MarkdownPage.Pirvacy:
      return Privacy;
    case MarkdownPage.Terms:
      return Terms;
    default:
      throw new Error(`${page} is not a valid Markdown page`);
  }
};

interface Props {
  page: MarkdownPage;
}

export const MarkdownLoader: React.FC<Props> = ({ page }) => {
  const [content, setContent] = useState({ md: '' });

  useEffect(() => {
    fetch(getAssociatedMarkdown(page))
      .then((res) => res.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);

  return (
    <div className="flex justify-center">
      <article className="prose mx-auto max-w-5xl my-5 text-justify mx-5 md:mx-10 lg:w-5/6">
        <ReactMarkdown children={content.md} />
      </article>
    </div>
  );
};
