import { FC, useEffect, useState } from 'react';
import { CheckoutMethod } from '../../hooks/usePaddle';
import { isDevelopment, isPreProd } from '../../utils/feature';
import { useCheckout } from '../../hooks/useCheckout';
import {
  PricingSubscriptionType,
  PricingSubscription,
  DiscountType
} from './PricingSubscription';
import { SubscritptionName } from '../../utils/subscription';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';

type PricingType = {
  subscriptions: PricingSubscriptionType[];
};

const paddleProdConfig = {
  FREE: 'FREE',
  ANNUAL_STARTER: '810646',
  MONTHLY_STARTER: '810641',
  ANNUAL_PRO: '810644',
  MONTHLY_PRO: '810643',
  ANNUAL_BUSINESS: '810645',
  MONTHLY_BUSINESS: '810642'
};

const paddleDevConfig = {
  FREE: 'FREE',
  ANNUAL_STARTER: '46270',
  MONTHLY_STARTER: '41983',
  ANNUAL_PRO: '46269',
  MONTHLY_PRO: '43876',
  ANNUAL_BUSINESS: '46272',
  MONTHLY_BUSINESS: '43426'
};

const paddleProductConfig =
  isDevelopment() || isPreProd() ? paddleDevConfig : paddleProdConfig;

export const pricingConfig: PricingType = {
  subscriptions: [
    {
      monthly: {
        name: SubscritptionName.FREE,
        id: paddleProductConfig[SubscritptionName.FREE],
        isCurrent: false,
        price: 0
      },
      annually: {
        name: SubscritptionName.FREE,
        id: paddleProductConfig[SubscritptionName.FREE],
        isCurrent: false,
        price: 0
      },
      name: 'Free',
      isPopular: false,
      display: true
    },
    {
      monthly: {
        name: SubscritptionName.MONTHLY_STARTER,
        id: paddleProductConfig[SubscritptionName.MONTHLY_STARTER],
        isCurrent: false,
        price: 4.99
      },
      annually: {
        name: SubscritptionName.ANNUAL_STARTER,
        id: paddleProductConfig[SubscritptionName.ANNUAL_STARTER],
        isCurrent: false,
        price: 4.17
      },
      name: 'Starter',
      isPopular: false,
      display: true
    },
    {
      monthly: {
        name: SubscritptionName.MONTHLY_PRO,
        id: paddleProductConfig[SubscritptionName.MONTHLY_PRO],
        isCurrent: false,
        price: 8.99
      },
      annually: {
        name: SubscritptionName.ANNUAL_PRO,
        id: paddleProductConfig[SubscritptionName.ANNUAL_PRO],
        isCurrent: false,
        price: 7.5
      },
      name: 'Pro',
      isPopular: true,
      display: true
    },
    {
      monthly: {
        name: SubscritptionName.MONTHLY_BUSINESS,
        id: paddleProductConfig[SubscritptionName.MONTHLY_BUSINESS],
        isCurrent: false,
        price: 19.99
      },
      annually: {
        name: SubscritptionName.ANNUAL_BUSINESS,
        id: paddleProductConfig[SubscritptionName.ANNUAL_BUSINESS],
        isCurrent: false,
        price: 16.66
      },
      name: 'Business',
      isPopular: false,
      display: true
    }
  ]
};

const updatePricingConfig = (
  pricingConfig: PricingType,
  currentPlanName?: SubscritptionName
) => {
  if (!currentPlanName) return true;

  for (let index = 0; index < pricingConfig.subscriptions.length; index++) {
    const subscription = pricingConfig.subscriptions[index];
    pricingConfig.subscriptions[index].monthly.isCurrent =
      subscription.monthly.name === currentPlanName;
    pricingConfig.subscriptions[index].annually.isCurrent =
      subscription.annually.name === currentPlanName;
  }
};

const isCurrentPlanMonthly = (pricingConfig: PricingType) => {
  for (let index = 0; index < pricingConfig.subscriptions.length; index++) {
    if (pricingConfig.subscriptions[index].monthly.isCurrent) {
      return true;
    }
  }
  return false;
};

interface Props {
  currentPlanName?: SubscritptionName;
  cancelUrl?: string;
}

const Pricing: FC<Props> = ({ currentPlanName, cancelUrl }) => {
  const { setCheckoutMethod } = useCheckout();
  const [isMonthly, setIsMonthly] = useState(false);
  const { t } = useTranslation();

  updatePricingConfig(pricingConfig, currentPlanName);

  if (posthog.getFeatureFlag('PricingDiscount')) {
    const discount = posthog.getFeatureFlagPayload(
      'PricingDiscount'
    ) as DiscountType;
    pricingConfig.subscriptions[2].discount = discount;
  }

  useEffect(() => {
    setCheckoutMethod?.(CheckoutMethod.Overlay);
  }, []);

  useEffect(() => {
    if (currentPlanName) {
      setIsMonthly(isCurrentPlanMonthly(pricingConfig));
    }
  }, [currentPlanName]);

  if (posthog.getFeatureFlag('PlanReduction') === 'test') {
    pricingConfig.subscriptions[1].display = false;
    pricingConfig.subscriptions[3].display = false;
  }

  const styleSelected = 'bg-blue-500 text-white';

  return (
    <section className="flex flex-col items-center text-gray-600 body-font overflow-hidden">
      <div className="text-3xl font-medium p-4">{t('pricing.title')}</div>
      <div className="container px-5 mx-auto">
        <div className="flex flex-col text-center w-full mb-5">
          <div className="flex mx-auto border-2 border-blue-500 rounded overflow-hidden mt-6">
            <button
              className={`py-1 px-4 focus:outline-none ${
                isMonthly && styleSelected
              }`}
              onClick={() => setIsMonthly(true)}
            >
              {t('pricing.monthly')}
            </button>
            <button
              className={`py-1 px-4 focus:outline-none ${
                !isMonthly && styleSelected
              }`}
              onClick={() => setIsMonthly(false)}
            >
              {t('pricing.yearly')}
            </button>
          </div>
        </div>
        <div className="flex flex-wrap py-4 justify-center">
          {pricingConfig.subscriptions.map((subscription) => (
            <PricingSubscription
              subscription={subscription}
              isMonthly={isMonthly}
              cancelUrl={cancelUrl}
              discount={isMonthly ? subscription.discount : undefined}
              key={subscription.name}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export { Pricing };
