import * as React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { ProtectedRoutesAuthComponents } from './ProtectedRoutesLayout';
import { isExtension } from '../utils/feature';
import { LoaderWithText } from './Loader';
import { useTranslation } from 'react-i18next';

interface AuthFormProps {
  isSignUpPage: boolean;
}

export const AuthForm: React.FC<AuthFormProps> = ({ isSignUpPage }) => {
  const { t } = useTranslation();
  return (
    <Authenticator
      signUpAttributes={['name']}
      components={ProtectedRoutesAuthComponents}
      hideSignUp={isExtension()}
      initialState={isSignUpPage ? 'signUp' : 'signIn'}
    >
      <LoaderWithText text={t('account.loader')} />
    </Authenticator>
  );
};
