import { SummarizationType } from '../data/summary';
import { SummaryConfiguration } from './summary';
import { getYoutubeId } from './isYoutubeUrl';
import { SummaryUserConfiguration } from '../components/SummarizerConfigurator';

function normalizeSummaryRequestSource<
  T extends SummaryUserConfiguration | SummaryConfiguration
>(config: T): T {
  return {
    ...config,
    ...(config.type === SummarizationType.Youtube
      ? { source: getYoutubeId(config.source) }
      : null)
  } as T;
}

export { normalizeSummaryRequestSource };
