import { FC, useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { requestUserInfo } from '../api/api';
import { isAuthentificated } from '../hooks/useAuth';
import { UserInfo } from '../models/UserInfo';
import { Events } from '../utils/events';
import { isExtension } from '../utils/feature';
import { AuthForm } from '../components/AuthForm';
import { Pricing } from '../components/pricing/Pricing';
import { enumFromStringValue } from '../utils/object';
import { useTranslation } from 'react-i18next';

export enum OutOfCreditsReason {
  outOfFreeCredits = 'outOfFreeCredits',
  outOfCredits = 'outOfCredits',
  Unknown = 'Unknown'
}

const outOfFreeCredits = {
  title: 'outOfCredits.free.title',
  subtitle: 'outOfCredits.free.subtitle',
  CTA: 'outOfCredits.free.CTA'
};

const outOfCredits = {
  title: 'outOfCredits.paid.title',
  subtitle: 'outOfCredits.paid.subtitle',
  CTA: 'outOfCredits.paid.CTA'
};

const getContent = (reason: OutOfCreditsReason) => {
  switch (reason) {
    case OutOfCreditsReason.outOfCredits:
      return outOfCredits;
    case OutOfCreditsReason.outOfFreeCredits:
      return outOfFreeCredits;
    default:
      throw new Error('No OutOfCreditsReason for: ' + reason);
  }
};

export const OutOfCredits: FC = () => {
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState<UserInfo>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParamReason = searchParams.get('reason');

  if (userData?.tokens && userData?.tokens > 0) {
    navigate('/');
  }

  if (!searchParamReason) {
    return <Navigate to="/profile" />;
  }

  const reason =
    enumFromStringValue(OutOfCreditsReason, searchParamReason) ||
    OutOfCreditsReason.Unknown;

  if (reason === OutOfCreditsReason.Unknown) {
    return <Navigate to="/profile" />;
  }

  if (isExtension() && isAuthentificated()) {
    if (reason === OutOfCreditsReason.outOfFreeCredits) navigate('/');
    else if (reason === OutOfCreditsReason.outOfCredits) {
      navigate(
        `https://app.recapiogpt.com/out-of-credits?reason=${OutOfCreditsReason.outOfCredits}`
      );
    }
  }

  const { title, subtitle, CTA } = getContent(reason);

  const fetchUserInfo = () => requestUserInfo().then(setUserData);

  useEffect(() => {
    document.addEventListener(Events.PaddlePaymentClose, fetchUserInfo);
    return () => {
      document.removeEventListener(Events.PaddlePaymentClose, fetchUserInfo);
    };
  }, []);

  const shouldDisplayPricing =
    OutOfCreditsReason.outOfCredits && !isExtension() && isAuthentificated();

  if (shouldDisplayPricing && !userData) {
    fetchUserInfo();
  }

  return (
    <div className="mx-auto p-4 flex-1 flex flex-col justify-center items-center">
      <div className="flex flex-col items-center text-center p-4">
        <div className="w-full">
          <div className="text-3xl font-medium p-4">{t(title)}</div>
          <div className="text-xl font-medium mb-4">{t(subtitle)}</div>
          <div className="text-lg mb-8">{t(CTA)}</div>
        </div>
      </div>
      {!isExtension() &&
      reason === OutOfCreditsReason.outOfCredits &&
      isAuthentificated() ? (
        <Pricing
          currentPlanName={userData?.planName}
          cancelUrl={userData?.cancelUrl}
        />
      ) : (
        <AuthForm
          isSignUpPage={
            !isExtension() && reason === OutOfCreditsReason.outOfFreeCredits
          }
        />
      )}
    </div>
  );
};
