import { Navbar } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isAuthentificated } from '../hooks/useAuth';
import { ReactComponent as Logo } from '../resources/images/logo.svg';
import {
  isChromiumBrowser,
  isExtension,
  isFirefoxBrowser
} from '../utils/feature';
import { LanguageDropdown } from './LanguageDropdown';

const navigation = [
  { id: 'home', href: '/', current: false },
  { id: 'saved', href: '/saved', current: false },
  { id: 'profile', href: '/profile', current: false }
];

let extensionUrl: string | undefined = undefined;
if (!isExtension()) {
  if (isChromiumBrowser()) {
    extensionUrl =
      'https://chrome.google.com/webstore/detail/ai-alfred/eeeahhaanadpgonocbinjbhbohcknfcd';
  } else if (isFirefoxBrowser()) {
    // extensionUrl = 'https://addons.mozilla.org/en-GB/firefox/addon/ai-alfred/';
  }
}

const CollapsedLinks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="md:hidden">
      {navigation.map((item) => (
        <Navbar.Link
          key={item.id}
          onClick={() => navigate(item.href)}
          active={item.current}
        >
          {item.id === 'profile' && !isAuthentificated()
            ? t('account.signIn')
            : t('navbar.' + item.id)}
        </Navbar.Link>
      ))}
    </div>
  );
};

const NavigationLinks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const websiteNavigation = !isExtension() ? 'hidden md:flex' : 'flex';
  const selectedStyle = 'block cursor-pointer rounded text-blue-500 text-base';
  const unselectedStyle =
    'block cursor-pointer text-gray-400 hover:text-gray-700 text-base';

  return (
    <ul
      className={[
        'justify-center flex-row font-medium space-x-8',
        websiteNavigation
      ].join(' ')}
    >
      {navigation.map(
        (item) =>
          (!isExtension() ||
            (isExtension() &&
              (isAuthentificated() ||
                (!isAuthentificated() &&
                  (item.id === 'home' || item.id === 'profile'))))) && (
            <li key={item.id} onClick={() => navigate(item.href)}>
              <a
                className={item.current ? selectedStyle : unselectedStyle}
                aria-current="page"
              >
                {t('navbar.' + item.id)}
              </a>
            </li>
          )
      )}
    </ul>
  );
};

export const AppNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openHome = () => navigate('/');
  const openLogin = () => navigate('/profile');
  const openSignUp = () => navigate('/profile?initialState=signUp');
  const openExtension = () => window.open(extensionUrl, '_blank');

  navigation.forEach((item) => {
    item.current = item.href === location.pathname;
  });

  return (
    <Navbar fluid={true} rounded={true}>
      <div className="flex flex-1 flex-wrap justify-between items-center mx-auto space-y-2">
        <Navbar.Brand onClick={openHome}>
          <Logo />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white ml-4">
            {t('navbar.logo')}
          </span>
        </Navbar.Brand>
        <NavigationLinks />
        <div className="flex space-x-2 justify-end">
          <LanguageDropdown />
          {!isAuthentificated() && (
            <button
              onClick={openLogin}
              className="hidden lg:block text-gray-800 dark:text-white bg-gray-200 hover:bg-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
            >
              {t('account.signIn')}
            </button>
          )}
          {!isExtension() && (
            <>
              {extensionUrl ? (
                <button
                  onClick={openExtension}
                  className="hidden lg:block text-white bg-blue-700 border border-transparent hover:bg-blue-800 disabled:hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
                >
                  <span className="flex items-center rounded-md text-sm px-4 py-2">
                    {t('navbar.downloadExtension')}
                  </span>
                </button>
              ) : (
                !isAuthentificated() && (
                  <button
                    onClick={openSignUp}
                    className="hidden lg:block text-white bg-blue-700 border border-transparent hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
                  >
                    <span className="flex items-center rounded-md text-sm px-4 py-2">
                      {t('navbar.getStarted')}
                    </span>
                  </button>
                )
              )}
              <Navbar.Toggle className="md:hidden" />
            </>
          )}
        </div>
        <Navbar.Collapse>
          <CollapsedLinks />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
