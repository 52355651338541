// https://gitlab.com/recapiogpt/alfred-summary-stack/-/blob/main/handlers/SummaryLambdaFunction/url_extract.py#L38

export const YOUTUBE_REGEX = new RegExp(
    '(https?://)?(www\\.)?'
    + '(youtube|youtu|youtube-nocookie)\\.(com|be)/'
    + '(watch\\?v=|embed/|v/|.+\\?v=)?([^&=%\\?]{11})'
);

export function isYoutubeUrl(url: string): boolean {
    return Boolean(YOUTUBE_REGEX.test(url));
}

export function getYoutubeId(url: string): string | undefined {
    if (!url) {
        return undefined
    }

    const match = url.match(YOUTUBE_REGEX);
    return match ? match[6] : undefined;
}

export function getYoutubeUrlFromId(id: string): string | undefined {
    if (!id) {
        return undefined
    }

    return `https://www.youtube.com/watch?v=${id}`;
}
