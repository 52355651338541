import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';
import { UserInfo } from '../models/UserInfo';
import { SubscritptionName } from '../utils/subscription';
import { Feature, getSubscritptionFeatures } from './pricing/Feature';

interface ProfileInformationProps {
  userData?: UserInfo;
  isLifetimeSubscription: boolean;
  subscritptionName: SubscritptionName;
}

export const ProfileInformation: React.FC<ProfileInformationProps> = ({
  userData,
  isLifetimeSubscription,
  subscritptionName
}) => {
  const { t } = useTranslation();
  const user = useAuth();
  const features = getSubscritptionFeatures(subscritptionName);

  return (
    <div>
      <div className="text-3xl font-medium p-4 text-center">
        {t('profile.title')}
      </div>
      <div className="text-base">
        {t('profile.email', { email: user?.attributes?.email })}
      </div>
      {userData && (
        <>
          <div className="text-base">
            {t('profile.credits.remaining', { count: userData?.tokens })}
          </div>
          <div className="text-base">
            {t('profile.credits.updateDate', {
              date: userData?.creditsRenewalDate.format('LL')
            })}
          </div>
        </>
      )}
      {isLifetimeSubscription &&
        subscritptionName != SubscritptionName.UNKNOWN && (
          <>
            <div className="text-base">
              {t('profile.subscription.lifetime.title')}
            </div>
            <div className="py-5">
              <p className="text-center text-xl text-bold pb-2">
                {t('profile.subscription.lifetime.details')}
              </p>
              {features.map((feature) => (
                <Feature
                  key={feature.key}
                  feature={feature}
                  subscritptionName={subscritptionName}
                />
              ))}
            </div>
          </>
        )}
    </div>
  );
};
