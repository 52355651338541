import { Accordion, Button, Label, Modal, Select } from 'flowbite-react';
import { posthog } from 'posthog-js';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpgrade } from '../hooks/useUpgrade';
import { isExtension } from '../utils/feature';
import { SubscritptionName } from '../utils/subscription';

const SUMMARY_TONE_LOCAL_STORAGE_KEY = 'summaryTone';
const SUMMARY_STYLE_LOCAL_STORAGE_KEY = 'summaryStyle';
const SUMMARY_LANGUAGE_LOCAL_STORAGE_KEY = 'summaryLanguage';
const SUMMARY_LENGTH_LOCAL_STORAGE_KEY = 'summaryLength';

const DEFAULT_SUMMARY_TONE = 'default';
const DEFAULT_SUMMARY_STYLE = 'default';
const DEFAULT_SUMMARY_LANGUAGE = 'default';
const DEFAULT_SUMMARY_LENGTH = 'medium';

interface ISummaryArguments {
  key: string;
  prompt: string;
}

const SUMMARY_TONES: ISummaryArguments[] = [
  {
    key: 'default',
    prompt: ''
  },
  {
    key: 'authoritative',
    prompt: 'authoritative'
  },
  {
    key: 'caring',
    prompt: 'caring'
  },
  {
    key: 'casual',
    prompt: 'casual'
  },
  {
    key: 'cheerful',
    prompt: 'cheerful'
  },
  {
    key: 'coarse',
    prompt: 'coarse'
  },
  {
    key: 'conserative',
    prompt: 'conserative'
  },
  {
    key: 'conversational',
    prompt: 'conversational'
  },
  {
    key: 'creative',
    prompt: 'creative'
  },
  {
    key: 'dry',
    prompt: 'dry'
  },
  {
    key: 'edgy',
    prompt: 'edgy'
  },
  {
    key: 'enthusiastic',
    prompt: 'enthusiastic'
  },
  {
    key: 'expository',
    prompt: 'expository'
  },
  {
    key: 'formal',
    prompt: 'formal'
  },
  {
    key: 'frank',
    prompt: 'frank'
  },
  {
    key: 'friendly',
    prompt: 'friendly'
  },
  {
    key: 'funny',
    prompt: 'funny'
  },
  {
    key: 'hummorous',
    prompt: 'hummorous'
  },
  {
    key: 'informative',
    prompt: 'informative'
  },
  {
    key: 'irreverent',
    prompt: 'irreverent'
  },
  {
    key: 'journalistic',
    prompt: 'journalistic'
  },
  {
    key: 'matter_of_fact',
    prompt: 'matter of fact'
  },
  {
    key: 'nostalgic',
    prompt: 'nostalgic'
  },
  {
    key: 'objective',
    prompt: 'objective'
  },
  {
    key: 'optimistic',
    prompt: 'optimistic'
  },
  {
    key: 'passionate',
    prompt: 'passionate'
  },
  {
    key: 'poetic',
    prompt: 'poetic'
  },
  {
    key: 'playful',
    prompt: 'playful'
  },
  {
    key: 'professional',
    prompt: 'professional'
  },
  {
    key: 'proactive',
    prompt: 'proactive'
  },
  {
    key: 'quirky',
    prompt: 'quirky'
  },
  {
    key: 'respectful',
    prompt: 'respectful'
  },
  {
    key: 'rhetorical',
    prompt: 'rhetorical'
  },
  {
    key: 'sarcastic',
    prompt: 'sarcastic'
  },
  {
    key: 'serious',
    prompt: 'serious'
  },
  {
    key: 'smart',
    prompt: 'smart'
  },
  {
    key: 'snarky',
    prompt: 'snarky'
  },
  {
    key: 'sophisticated',
    prompt: 'sophisticated'
  },
  {
    key: 'subjective',
    prompt: 'subjective'
  },
  {
    key: 'sympathetic',
    prompt: 'sympathetic'
  },
  {
    key: 'trendy',
    prompt: 'trendy'
  },
  {
    key: 'trustworthy',
    prompt: 'trustworthy'
  },
  {
    key: 'unapologetic',
    prompt: 'unapologetic'
  },
  {
    key: 'upbeat',
    prompt: 'upbeat'
  },
  {
    key: 'warm',
    prompt: 'warm'
  },
  {
    key: 'witty',
    prompt: 'witty'
  }
];

const SUMMARY_STYLES: ISummaryArguments[] = [
  {
    key: 'default',
    prompt: ''
  },
  {
    key: 'academic',
    prompt: 'academic'
  },
  {
    key: 'analytical',
    prompt: 'analytical'
  },
  {
    key: 'argumentative',
    prompt: 'argumentative'
  },
  {
    key: 'conversational',
    prompt: 'conversational'
  },
  {
    key: 'creative',
    prompt: 'creative'
  },
  {
    key: 'critical',
    prompt: 'critical'
  },
  {
    key: 'descriptive',
    prompt: 'descriptive'
  },
  {
    key: 'dramatic',
    prompt: 'dramatic'
  },
  {
    key: 'epigrammatic',
    prompt: 'epigrammatic'
  },
  {
    key: 'epistolary',
    prompt: 'epistolary'
  },
  {
    key: 'expository',
    prompt: 'expository'
  },
  {
    key: 'informative',
    prompt: 'informative'
  },
  {
    key: 'instructive',
    prompt: 'instructive'
  },
  {
    key: 'journalistic',
    prompt: 'journalistic'
  },
  {
    key: 'metaphorical',
    prompt: 'metaphorical'
  },
  {
    key: 'narrative',
    prompt: 'narrative'
  },
  {
    key: 'persuasive',
    prompt: 'persuasive'
  },
  {
    key: 'poetic',
    prompt: 'poetic'
  },
  {
    key: 'rhetorical',
    prompt: 'rhetorical'
  },
  {
    key: 'satirical',
    prompt: 'satirical'
  },
  {
    key: 'scientific',
    prompt: 'scientific'
  },
  {
    key: 'technical',
    prompt: 'technical'
  }
];

const SUMMARY_LANGUAGES: ISummaryArguments[] = [
  { key: 'default', prompt: '{language}' },
  { key: 'af', prompt: 'Afrikaans' },
  { key: 'ar', prompt: 'Arabic' },
  { key: 'bg', prompt: 'Bulgarian' },
  { key: 'bn', prompt: 'Bengali' },
  { key: 'ca', prompt: 'Catalan' },
  { key: 'cs', prompt: 'Czech' },
  { key: 'cy', prompt: 'Welsh' },
  { key: 'da', prompt: 'Danish' },
  { key: 'de', prompt: 'German' },
  { key: 'el', prompt: 'Greek' },
  { key: 'en', prompt: 'English' },
  { key: 'es', prompt: 'Spanish' },
  { key: 'et', prompt: 'Estonian' },
  { key: 'fa', prompt: 'Persian' },
  { key: 'fi', prompt: 'Finnish' },
  { key: 'fr', prompt: 'French' },
  { key: 'gu', prompt: 'Gujarati' },
  { key: 'he', prompt: 'Hebrew' },
  { key: 'hi', prompt: 'Hindi' },
  { key: 'hr', prompt: 'Croatian' },
  { key: 'hu', prompt: 'Hungarian' },
  { key: 'id', prompt: 'Indonesian' },
  { key: 'it', prompt: 'Italian' },
  { key: 'ja', prompt: 'Japanese' },
  { key: 'kn', prompt: 'Kannada' },
  { key: 'ko', prompt: 'Korean' },
  { key: 'lt', prompt: 'Lithuanian' },
  { key: 'lv', prompt: 'Latvian' },
  { key: 'mk', prompt: 'Macedonian' },
  { key: 'ml', prompt: 'Malayalam' },
  { key: 'mr', prompt: 'Marathi' },
  { key: 'ne', prompt: 'Nepali' },
  { key: 'nl', prompt: 'Dutch' },
  { key: 'no', prompt: 'Norwegian' },
  { key: 'pa', prompt: 'Punjabi' },
  { key: 'pl', prompt: 'Polish' },
  { key: 'pt', prompt: 'Portuguese' },
  { key: 'ro', prompt: 'Romanian' },
  { key: 'ru', prompt: 'Russian' },
  { key: 'sk', prompt: 'Slovak' },
  { key: 'sl', prompt: 'Slovenian' },
  { key: 'so', prompt: 'Somali' },
  { key: 'sq', prompt: 'Albanian' },
  { key: 'sv', prompt: 'Swedish' },
  { key: 'sw', prompt: 'Swahili' },
  { key: 'ta', prompt: 'Tamil' },
  { key: 'te', prompt: 'Telugu' },
  { key: 'th', prompt: 'Thai' },
  { key: 'tl', prompt: 'Tagalog' },
  { key: 'tr', prompt: 'Turkish' },
  { key: 'uk', prompt: 'Ukrainian' },
  { key: 'ur', prompt: 'Urdu' },
  { key: 'vi', prompt: 'Vietnamese' },
  { key: 'zh-cn', prompt: 'Chinese (Simplified)' },
  { key: 'zh-tw', prompt: 'Chinese (Traditional)' }
];

const SUMMARY_LENGTHS: ISummaryArguments[] = [
  {
    key: 'short',
    prompt: '1 long paragraph'
  },
  {
    key: 'medium',
    prompt: '3 long paragraphs'
  },
  {
    key: 'long',
    prompt: '6 long paragraphs'
  }
];

export const capturePromptConfiguration = () => {
  const summaryTone = localStorage.getItem(SUMMARY_TONE_LOCAL_STORAGE_KEY);
  const summaryStyle = localStorage.getItem(SUMMARY_STYLE_LOCAL_STORAGE_KEY);
  const summaryLanguage = localStorage.getItem(
    SUMMARY_LANGUAGE_LOCAL_STORAGE_KEY
  );
  const summaryLength = localStorage.getItem(SUMMARY_LENGTH_LOCAL_STORAGE_KEY);
  posthog.capture('SummaryPromptConfiguration', {
    summaryTone,
    summaryStyle,
    summaryLanguage,
    summaryLength
  });
};

const getValueFromKey = (key: string, array: ISummaryArguments[]) => {
  const index = array.findIndex((item) => item.key === key);
  return index > -1 ? array[index] : array[0];
};

const getDefaultValue = (
  localStorageKey: string,
  defaultKey: string,
  array: ISummaryArguments[]
) => {
  const key = localStorage.getItem(localStorageKey) || defaultKey;
  return getValueFromKey(key, array);
};

interface Props {
  setPrompt: (prompt: string) => void;
}

const AdvancedSummarizerConfigurator: React.FC<Props> = ({ setPrompt }) => {
  const { t } = useTranslation();
  const { canUseFeature } = useUpgrade();
  const [summaryTone, setSummaryTone] = useState<ISummaryArguments>(
    getDefaultValue(
      SUMMARY_TONE_LOCAL_STORAGE_KEY,
      DEFAULT_SUMMARY_TONE,
      SUMMARY_TONES
    )
  );
  const [summaryStyle, setSummaryStyle] = useState<ISummaryArguments>(
    getDefaultValue(
      SUMMARY_STYLE_LOCAL_STORAGE_KEY,
      DEFAULT_SUMMARY_STYLE,
      SUMMARY_STYLES
    )
  );
  const [summaryLanguage, setSummaryLanguage] = useState<ISummaryArguments>(
    getDefaultValue(
      SUMMARY_LANGUAGE_LOCAL_STORAGE_KEY,
      DEFAULT_SUMMARY_LANGUAGE,
      SUMMARY_LANGUAGES
    )
  );
  const [summaryLength, setSummaryLength] = useState<ISummaryArguments>(
    getDefaultValue(
      SUMMARY_LENGTH_LOCAL_STORAGE_KEY,
      DEFAULT_SUMMARY_LENGTH,
      SUMMARY_LENGTHS
    )
  );

  useEffect(() => {
    let prompt = `Summarize the following text in ${summaryLength.prompt}. `;
    if (summaryTone.key != DEFAULT_SUMMARY_TONE) {
      prompt += `Use a ${summaryTone.prompt} tone of voice. `;
    }
    if (summaryStyle.key != DEFAULT_SUMMARY_STYLE) {
      prompt += `Use a ${summaryStyle.prompt} writing style. `;
    }
    prompt += `The summary should be in ${summaryLanguage.prompt}: \n{text_to_summarize}\n`;
    setPrompt(prompt);
  }, [summaryTone, summaryLanguage, summaryLength, setPrompt]);

  const updateSummaryTone = (event: ChangeEvent<HTMLSelectElement>) => {
    let selectedValue = event.target.value;
    if (!canUseFeature(SubscritptionName.MONTHLY_STARTER, true)) {
      selectedValue = DEFAULT_SUMMARY_TONE;
    }

    const selectedSummaryTone = getValueFromKey(selectedValue, SUMMARY_TONES);
    setSummaryTone(selectedSummaryTone);
    localStorage.setItem(
      SUMMARY_TONE_LOCAL_STORAGE_KEY,
      selectedSummaryTone.key
    );
    posthog.capture('SummaryPromptUpdate', {
      summaryTone: selectedSummaryTone.key
    });
  };

  const updateSummaryStyle = (event: ChangeEvent<HTMLSelectElement>) => {
    let selectedValue = event.target.value;
    if (!canUseFeature(SubscritptionName.MONTHLY_STARTER, true)) {
      selectedValue = DEFAULT_SUMMARY_STYLE;
    }

    const selectedSummaryStyle = getValueFromKey(selectedValue, SUMMARY_STYLES);
    setSummaryStyle(selectedSummaryStyle);
    localStorage.setItem(
      SUMMARY_STYLE_LOCAL_STORAGE_KEY,
      selectedSummaryStyle.key
    );
    posthog.capture('SummaryPromptUpdate', {
      summaryStyle: selectedSummaryStyle.key
    });
  };

  const updateSummaryLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    let selectedValue = event.target.value;
    if (!canUseFeature(SubscritptionName.MONTHLY_STARTER, true)) {
      selectedValue = DEFAULT_SUMMARY_LANGUAGE;
    }

    const selectedSummaryLanguage = getValueFromKey(
      selectedValue,
      SUMMARY_LANGUAGES
    );
    setSummaryLanguage(selectedSummaryLanguage);
    localStorage.setItem(
      SUMMARY_LANGUAGE_LOCAL_STORAGE_KEY,
      selectedSummaryLanguage.key
    );
    posthog.capture('SummaryPromptUpdate', {
      summaryLanguage: selectedSummaryLanguage.key
    });
  };

  const updateSummaryLength = (event: ChangeEvent<HTMLSelectElement>) => {
    let selectedValue = event.target.value;
    if (!canUseFeature(SubscritptionName.MONTHLY_STARTER, true)) {
      selectedValue = DEFAULT_SUMMARY_LENGTH;
    }

    const selectedSummaryLength = getValueFromKey(
      selectedValue,
      SUMMARY_LENGTHS
    );
    setSummaryLength(selectedSummaryLength);
    localStorage.setItem(
      SUMMARY_LENGTH_LOCAL_STORAGE_KEY,
      selectedSummaryLength.key
    );
    posthog.capture('SummaryPromptUpdate', {
      summaryLength: selectedSummaryLength.key
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <p className="text-base text-center">
        {t('summary.advancedConfiguration.description')}
      </p>
      <div className="flex space-y-4 md:space-y-0 md:space-x-4 flex-col md:flex-row md: justify-center">
        <div id="select">
          <div className="flex flex-row space-x-2 mb-2 block">
            <Label
              htmlFor="summaryTone"
              value={
                t('summary.advancedConfiguration.form.tone.label') ||
                'Voice Tones'
              }
            />
          </div>
          <Select
            id="summaryTone"
            required={true}
            defaultValue={summaryTone.key}
            onChange={(event) => updateSummaryTone(event)}
          >
            {SUMMARY_TONES.map((tone) => (
              <option key={tone.key} value={tone.key}>
                {t(
                  'summary.advancedConfiguration.form.tone.options.' + tone.key
                )}
              </option>
            ))}
          </Select>
        </div>
        <div id="select">
          <div className="flex flex-row space-x-2 mb-2 block">
            <Label
              htmlFor="summaryStyle"
              value={
                t('summary.advancedConfiguration.form.style.label') ||
                'Writing Style'
              }
            />
          </div>
          <Select
            id="summaryStyle"
            required={true}
            defaultValue={summaryStyle.key}
            onChange={(event) => updateSummaryStyle(event)}
          >
            {SUMMARY_STYLES.map((style) => (
              <option key={style.key} value={style.key}>
                {t(
                  'summary.advancedConfiguration.form.style.options.' +
                    style.key
                )}
              </option>
            ))}
          </Select>
        </div>
        <div id="select">
          <div className="mb-2 block">
            <Label
              htmlFor="summaryLanguage"
              value={
                t('summary.advancedConfiguration.form.language.label') ||
                'Language'
              }
            />
          </div>
          <Select
            id="summaryLanguage"
            required={true}
            defaultValue={summaryLanguage.key}
            onChange={(event) => updateSummaryLanguage(event)}
          >
            {SUMMARY_LANGUAGES.map((language) => (
              <option key={language.key} value={language.key}>
                {t(
                  'summary.advancedConfiguration.form.language.options.' +
                    language.key
                )}
              </option>
            ))}
          </Select>
        </div>
        <div id="select">
          <div className="mb-2 block">
            <Label
              htmlFor="summaryLength"
              value={
                t('summary.advancedConfiguration.form.length.label') || 'Length'
              }
            />
          </div>
          <Select
            id="summaryLength"
            required={true}
            defaultValue={summaryLength.key}
            onChange={(event) => updateSummaryLength(event)}
          >
            {SUMMARY_LENGTHS.map((length) => (
              <option key={length.key} value={length.key}>
                {t(
                  'summary.advancedConfiguration.form.length.options.' +
                    length.key
                )}
              </option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export const ExtensionAdvancedConfigurator: React.FC<Props> = ({
  setPrompt
}) => {
  const [visibility, setVisibility] = useState(false);
  const { t } = useTranslation();

  const onDisplayModal = () => {
    setVisibility(true);
  };
  const onCloseModal = () => setVisibility(false);

  if (!isExtension()) return null;
  return (
    <>
      <Button
        color="light"
        className="px-4 mt-3 md:w-1/5 w-full"
        onClick={onDisplayModal}
      >
        {t('summary.advancedConfiguration.title')}
      </Button>
      <Modal
        show={visibility}
        position="center"
        popup={true}
        size="md"
        onClose={onCloseModal}
        dismissible={true}
      >
        <Modal.Header>{t('summary.advancedConfiguration.title')}</Modal.Header>
        <Modal.Body>
          <AdvancedSummarizerConfigurator setPrompt={setPrompt} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export const WebAdvancedConfigurator: React.FC<Props> = ({ setPrompt }) => {
  const { t } = useTranslation();
  if (isExtension()) return null;
  return (
    <Accordion collapseAll={true} flush={true} className="w-full">
      <Accordion.Panel>
        <Accordion.Title>
          {t('summary.advancedConfiguration.title')}
        </Accordion.Title>
        <Accordion.Content>
          <AdvancedSummarizerConfigurator setPrompt={setPrompt} />
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};
