export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const preSummaryzeText = (text: string) => {
  const splittedText = text.split('\n');
  let cleanedText = '';

  splittedText.forEach((sentence) => {
    const sentenceLength = sentence.split(' ').length;
    if (sentenceLength >= 6) {
      cleanedText += sentence + ' ';
    }
  });
  return cleanedText;
};
