import { AccountSettings } from '@aws-amplify/ui-react';
import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';

export const PasswordChangeModal: React.FC = () => {
  const [visibility, setVisibility] = useState(false);
  const { t } = useTranslation();
  const user = useAuth();

  const onDisplayModal = () => setVisibility(true);
  const onCloseModal = () => setVisibility(false);

  const onChangePasswordSuccess = () => {
    toast.success('Password is successfully changed!');
    onCloseModal();
  };

  return (
    <>
      <Button
        color="light"
        className="px-4 mt-3 md:w-1/5 w-full"
        onClick={onDisplayModal}
      >
        {t('modal.passwordReset.button')}
      </Button>
      <Modal
        show={visibility}
        position="center"
        popup={true}
        size="md"
        onClose={onCloseModal}
        dismissible={true}
      >
        <Modal.Header>{t('modal.passwordReset.title')}</Modal.Header>
        <Modal.Body>
          <div className="text-md">
            {t('modal.passwordReset.email', {
              email: user?.attributes?.email
            })}
          </div>
          <AccountSettings.ChangePassword onSuccess={onChangePasswordSuccess} />
        </Modal.Body>
      </Modal>
    </>
  );
};
