import { posthog } from 'posthog-js';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Events,
  PaddleCheckoutState,
  PaddlePaymentCompleteEvent
} from '../utils/events';
import { getSubscritptionName } from '../utils/subscription';
import { useAuth } from './useAuth';
import usePaddle, {
  CheckoutConfig,
  CheckoutMethod,
  PaddleCallback,
  PaddleCallbakEvent
} from './usePaddle';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <CheckoutProvider>.');
};

interface CheckoutContext {
  environmentConfig?: {
    vendor: number;
    environment?: string;
    eventCallback?: (data: PaddleCallback) => void;
  };
  paddle?: unknown;
  selectedProductId?: string | null;
  setSelectedProductId?: Dispatch<SetStateAction<string | null>>;
  setDiscountCoupon?: Dispatch<SetStateAction<string | undefined>>;
  setCheckoutMethod?: Dispatch<SetStateAction<CheckoutMethod>>;
  userData?: unknown;
  setUserData?: Dispatch<SetStateAction<PaddleUserData>>;
  checkoutConfig?: Record<string, unknown> | null;
  passthroughData?: PassthroughData;
  onUpdateSubscription?: (subscriptionUpdateUrl: string) => void;
  onCancelSubscription?: (subscriptionCancelUrl: string) => void;
}

interface PaddleUserData {
  sub: string | null;
  email?: string;
  country?: string;
  postcode?: string;
}

export interface PassthroughData {
  purchaserId: string;
  referral?: string;
}

const CheckoutContext = createContext<CheckoutContext>({
  environmentConfig: {
    vendor: 0,
    environment: '',
    eventCallback: stub
  },
  paddle: null,
  selectedProductId: '',
  setSelectedProductId: stub,
  setDiscountCoupon: stub,
  userData: null,
  setUserData: stub,
  checkoutConfig: null,
  onUpdateSubscription: () => undefined,
  onCancelSubscription: () => undefined
});

const dispatchPaymentComplete = (checkoutState: PaddleCheckoutState) =>
  document.dispatchEvent(
    new CustomEvent<PaddlePaymentCompleteEvent>(Events.PaddlePaymentComplete, {
      detail: {
        checkoutState
      }
    })
  );

interface Props extends CheckoutContext {
  children: React.ReactNode;
}

export const CheckoutProvider: FC<Props> = ({
  environmentConfig,
  checkoutConfig,
  passthroughData,
  children
}) => {
  const { t } = useTranslation();

  if (!environmentConfig) throw new Error('Must provide environmentConfig');
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );
  const [discountCoupon, setDiscountCoupon] = useState<string>();
  const [checkoutState, setCheckoutState] = useState<PaddleCheckoutState>(
    PaddleCheckoutState.PAYEMENT
  );
  const [checkoutMehtod, setCheckoutMethod] = useState<CheckoutMethod>(
    CheckoutMethod.Overlay
  );
  const [userData, setUserData] = useState<PaddleUserData>({ sub: null });

  const eventCallback = (paddleCallback: PaddleCallback) => {
    switch (paddleCallback.event) {
      case PaddleCallbakEvent.Close:
        setSelectedProductId(null);
        if (checkoutState === PaddleCheckoutState.PAYEMENT) {
          document.dispatchEvent(new CustomEvent(Events.PaddlePaymentClose));
        }
        break;
      case PaddleCallbakEvent.Error:
        if (checkoutState === PaddleCheckoutState.PAYEMENT) {
          toast.error(t('checkout.payement.error'));
        }
        break;
      case PaddleCallbakEvent.Complete:
        dispatchPaymentComplete(checkoutState);
        if (checkoutState === PaddleCheckoutState.PAYEMENT) {
          const productId = paddleCallback.checkoutData.product;
          if (productId) {
            const subscriptionName = getSubscritptionName(productId);
            onUpdateSubscription(subscriptionName);
            posthog.capture('PaddlePayment', {
              product: selectedProductId,
              subscriptionName: subscriptionName
            });
          }
          toast.success(t('checkout.payement.success'));
        } else if (checkoutState === PaddleCheckoutState.UPDATE) {
          toast.success(t('checkout.update.success'));
        } else if (checkoutState === PaddleCheckoutState.CANCEL) {
          toast.success(t('checkout.cancel.success'));
        }
        break;

      default:
        break;
    }
    environmentConfig.eventCallback?.(paddleCallback);
  };

  const { paddle } = usePaddle({ ...environmentConfig, ...{ eventCallback } });
  const user = useAuth();

  useEffect(() => {
    if (!paddle || !selectedProductId) return;
    let checkoutParams: CheckoutConfig = {
      method: checkoutMehtod,
      product: selectedProductId,
      closeCallback: 'checkoutClosed',
      frameTarget:
        checkoutMehtod == CheckoutMethod.Inline ? 'checkout-container' : '',
      ...checkoutConfig,
      ...userData,
      frameStyle:
        checkoutMehtod == CheckoutMethod.Inline
          ? 'width:100%; min-width:280px;'
          : ''
    };

    if (discountCoupon) {
      checkoutParams = {
        ...checkoutParams,
        coupon: discountCoupon
      };
    }

    if (user?.attributes?.email) {
      checkoutParams.email = user?.attributes?.email;
      checkoutParams.disableLogout = true;
    }

    if (passthroughData) {
      checkoutParams = {
        ...checkoutParams,
        passthrough: JSON.stringify(passthroughData)
      };
    }

    setCheckoutState(PaddleCheckoutState.PAYEMENT);
    paddle.Checkout.open(checkoutParams);
  }, [paddle, checkoutMehtod, selectedProductId]);

  const onUpdateSubscription = (subscriptionUpdateUrl: string) => {
    if (paddle) {
      const checkoutParams: CheckoutConfig = {
        method: checkoutMehtod,
        override: subscriptionUpdateUrl
      };
      setCheckoutState(PaddleCheckoutState.UPDATE);
      paddle.Checkout.open(checkoutParams);
    } else console.log("Can't update subscriptiuon without a paddle instance");
  };

  const onCancelSubscription = (subscriptionCancelUrl: string) => {
    if (paddle) {
      const checkoutParams: CheckoutConfig = {
        method: checkoutMehtod,
        override: subscriptionCancelUrl
      };
      setCheckoutState(PaddleCheckoutState.CANCEL);
      paddle.Checkout.open(checkoutParams);
    } else console.log("Can't cancel subscriptiuon without a paddle instance");
  };

  return (
    <CheckoutContext.Provider
      value={{
        paddle,
        selectedProductId,
        setSelectedProductId,
        setDiscountCoupon,
        setCheckoutMethod,
        userData,
        setUserData,
        passthroughData,
        onUpdateSubscription,
        onCancelSubscription
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);
