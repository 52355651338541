import moment, { Moment } from 'moment';
import { enumFromStringValue } from '../utils/object';
import { SubscritptionName } from '../utils/subscription';

export enum SubscritptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  PastDue = 'past_due',
  Paused = 'paused',
  Deleted = 'deleted',
  Unknown = 'unknown'
}
interface ApiUserInfoResponse {
  tokens: number;
  generatedSummary: number;
  status: string;
  planName: string;
  cancelUrl: string;
  updateUrl: string;
  endDate: string;
  creditsRenewalDate: string;
}

export class UserInfo {
  tokens: number;
  generatedSummary: number;
  status: SubscritptionStatus;
  planName: SubscritptionName;
  cancelUrl: string;
  updateUrl: string;
  endDate: Moment;
  creditsRenewalDate: Moment;

  constructor(
    tokens: number,
    generatedSummary: number,
    status: string,
    planName: string,
    endDate: string,
    cancelUrl: string,
    updateUrl: string,
    creditsRenewalDate: string
  ) {
    this.tokens = tokens;
    this.generatedSummary = generatedSummary;
    this.status =
      enumFromStringValue(SubscritptionStatus, status) ||
      SubscritptionStatus.Unknown;
    this.planName =
      enumFromStringValue(SubscritptionName, planName) ||
      SubscritptionName.UNKNOWN;
    this.endDate = moment(endDate);
    this.cancelUrl = cancelUrl;
    this.updateUrl = updateUrl;
    this.creditsRenewalDate = moment(creditsRenewalDate);
  }

  static fromAPI(item?: ApiUserInfoResponse): UserInfo {
    if (!item) throw new Error('No item!');
    return new UserInfo(
      item.tokens,
      item.generatedSummary,
      item.status,
      item.planName,
      item.endDate,
      item.cancelUrl,
      item.updateUrl,
      item.creditsRenewalDate
    );
  }
}
